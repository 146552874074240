const breakPoint = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 960,
      lg: 1350,
      xl: 1800,
    },
  },
};
export default breakPoint;

import { styled } from "@mui/material";
import { Box } from "@mui/system";
import DataTable from "../../components/tables/DataTable";
import CustomDialog from "../../components/layouts/common/CustomDialog";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({ filters, setFilters, list, loading, columns }) => {
  return (
    <>
      <CustomDialog id="distributor-list" title="Distributor List">
        <Box sx={{ minHeight: "300px" }}>
          <DataTable
            hidePagination
            columns={columns}
            rows={list}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box>
      </CustomDialog>
    </>
  );
};
export default ListUi;

import CustomInput from "../../components/inputs/CustomInput";
import { Box, Typography, styled } from "@mui/material";
import OneViewBox from "../../components/layouts/OneViewBox";
import SubmitButton from "../../components/button/SubmitButton";
import { center } from "../../assets/css/theme/common";
import SolarBg from "../../assets/images/solar-bg.svg";
import Logo from "../../assets/images/logo.png";
import ImageComponent from "../../components/ImageComponent";

const absolutePosition = {
  position: "absolute",
  top: "0px",
  left: "0px",
  height: "100%",
  width: "100%",
  zIndex: -1,
};
const SignInBoxUi = styled(Box)(({ theme }) => ({
  display: "flex",
  maxWidth: "90%",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  backdropFilter: "blur(10px)",
  width: "600px",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  border: "1px solid " + theme.palette.primary.main,
  "::after": {
    content: '" "',
    position: "absolute",
    height: "100%",
    width: "100%",
    left: "0px",
    top: "0px",
    background: theme.palette.light.main,
    opacity: 0.51,
    zIndex: -1,
  },
}));

const SignInUI = ({ state, setState, onSubmit, loading }) => {
  return (
    <>
      <OneViewBox sx={{ overflow: "hidden" }}>
        <Box sx={absolutePosition}>
          <ImageComponent
            isStatic
            noClickEffect={true}
            sx={{}}
            src={SolarBg}
            alt={"Background"}
          />
        </Box>
        <Box sx={{ ...center, width: "100%", height: "100%" }}>
          <SignInBoxUi
            p={4}
            component={"form"}
            width={"100%"}
            onSubmit={onSubmit}
          >
            <Box sx={{ width: "200px", height: "auto" }}>
              {" "}
              <ImageComponent
                isStatic
                noClickEffect={true}
                sx={{ height: "auto", objectFit: "contain" }}
                src={Logo}
                alt={"Background"}
              />
            </Box>
            <Typography align="center" variant="h5" color={"red"} mb={1}>
              {state.err}
            </Typography>
            <Box>
              <CustomInput
                variant="filled"
                disabled={loading}
                value={state.email}
                onChange={(e) =>
                  setState({ ...state, err: "", email: e.target.value })
                }
                type="text"
                label={"Email"}
              />
              <CustomInput
                variant="filled"
                disabled={loading}
                value={state.password}
                onChange={(e) =>
                  setState({ ...state, err: "", password: e.target.value })
                }
                type="password"
                label={"Password"}
              />
              <Box mt={4}>
                <SubmitButton
                  variant="contained"
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  title="Sign In"
                />
              </Box>
            </Box>
          </SignInBoxUi>
        </Box>
      </OneViewBox>
    </>
  );
};
export default SignInUI;

import {
  CircularProgress,
  Box,
  Grid,
  InputAdornment,
  Select,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import { Fragment, memo } from "react";
import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import COUNTRY_CODES from "../../utils/CountryCode";
import GoogleAddress from "../../components/inputs/GoogleAddress";
import DynamicDataDropDown from "../../components/inputs/DynamicDataDropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  ENQUIRY_SOURCES,
  ENQUIRY_TYPES_IDENTIFIER,
} from "../../utils/constants/lead.constants";
import { CheckCircle, ContactEmergency, People } from "@mui/icons-material";
import AreaCreateController from "../area/AreaCreateController";
import {
  addDistributionAreaData,
  fetchDistributionAreaAction,
} from "../../store/actions/distrubutionAreaAction";
import { openModal } from "../../store/actions/modalAction";
import DistributorListController from "./DistributorListController";
import FileInput from "../../components/inputs/FileInput";
import { ParentProductsList } from "../product/ProductDropDowns";

const SelectionButton = memo(({ title, active, onClick }) => (
  <Button
    disableElevation
    onClick={onClick}
    variant={active ? "contained" : "outlined"}
    sx={{ width: "100%", py: 3 }}
  >
    <Typography>{title}</Typography>
  </Button>
));
const TypeSelectionButton = memo(({ value, onChange = () => {} }) => {
  const { enquiryType } = useSelector((state) => state);
  return (
    <>
      <Grid container columnSpacing={1}>
        {enquiryType?.data &&
          enquiryType?.data?.map((item) => (
            <Grid item md={12 / enquiryType?.data?.length} key={item._id}>
              <SelectionButton
                title={item.name}
                active={item?._id === value?._id}
                onClick={() => onChange(item)}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
});
const AddEnquiryUI = ({
  title,
  modalKey,
  defaulData,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const {
    communicationChannel,
    product,
    distributionArea,
    source,
    enquiryType,
  } = useSelector((state) => state);

  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        // dialogProps={{ sx: { position: "relative" } }}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${title}`}
        closeText="Close"
        confirmText={`Submit`}
      >
        {loading && (
          <CenteredBox
            sx={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: "0px",
              left: "0px",
              zIndex: 11,
              background: "rgba(255,255,255,0.7)",
            }}
          >
            <CircularProgress />
          </CenteredBox>
        )}
        {
          <Box pt={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            ></Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ width: "100%" }}>
                  <DynamicDataDropDown
                    fullWidth
                    defaultValue={defaulData?.channel ?? null}
                    onChange={(e, val) =>
                      setFields({ ...fields, channel: val })
                    }
                    reducerDataObj={communicationChannel}
                    titleKey="channel_name"
                    valueKey="_id"
                    inputProps={{
                      label: "Communication Channel*",
                    }}
                  />
                </Box>
              </Grid>
              {fields.channel && (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography mt={2} mb={1}>
                      Enquiry Type:
                    </Typography>
                    <TypeSelectionButton
                      value={fields.type}
                      onChange={(val) =>
                        setFields({
                          ...fields,
                          type: val,
                          source: null,
                          product: null,
                          area: null,
                        })
                      }
                    />
                    <Box mb={4} />
                  </Grid>
                  <></>
                  <Grid item xs={12} md={4}>
                    <CustomInput
                      margin="none"
                      autoFocus={true}
                      disabled={loading}
                      value={fields.first_name}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          first_name: e.target.value,
                        })
                      }
                      type="text"
                      label={"First Name*"}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInput
                      margin="none"
                      disabled={loading}
                      value={fields.middle_name}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          middle_name: e.target.value,
                        })
                      }
                      type="text"
                      label={"Middle Name"}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInput
                      margin="none"
                      disabled={loading}
                      value={fields.last_name}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          last_name: e.target.value,
                        })
                      }
                      type="text"
                      label={"Last Name"}
                    />
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <CustomInput
                      margin="none"
                      disabled={loading}
                      value={fields.email}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          email: e.target.value,
                        })
                      }
                      type="email"
                      label={"Email"}
                    />
                  </Grid>
                  <Grid item xs={12} md={3.5}>
                    <CustomInput
                      margin="none"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Select
                              disableUnderline
                              value={fields.country_code}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  err: "",
                                  country_code: e.target.value,
                                })
                              }
                              variant="standard"
                              size="small"
                            >
                              {COUNTRY_CODES.map((item) => (
                                <MenuItem
                                  key={item.code}
                                  value={item.dial_code}
                                >
                                  {item.code}({item.dial_code})
                                </MenuItem>
                              ))}
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                      disabled={loading}
                      value={fields.phone}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          phone: e.target.value,
                        })
                      }
                      type="tel"
                      label={"Phone No.*"}
                    />
                  </Grid>

                  <Grid item xs={12} md={3.5}>
                    <CustomInput
                      margin="none"
                      disabled={loading}
                      value={fields.other_phone}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          other_phone: e.target.value,
                        })
                      }
                      type="tel"
                      label={"Other Phone No"}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={
                      fields?.type?.identifier ===
                        ENQUIRY_TYPES_IDENTIFIER.INFORMATION ||
                      fields?.type?.identifier ===
                        ENQUIRY_TYPES_IDENTIFIER.PURCHASED
                        ? 6
                        : 12
                    }
                  >
                    <CustomInput
                      margin="none"
                      disabled={loading}
                      value={fields.address}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          address: e.target.value,
                        })
                      }
                      type="text"
                      label={"Address(Street / Village)"}
                    />
                  </Grid>

                  {fields?.type?.identifier &&
                    (fields?.type?.identifier ===
                      ENQUIRY_TYPES_IDENTIFIER.INFORMATION ||
                      fields?.type?.identifier ===
                        ENQUIRY_TYPES_IDENTIFIER.PURCHASED) && (
                      <Grid item xs={12} md={6}>
                        <DynamicDataDropDown
                          fullWidth
                          creatable
                          defaultValue={defaulData?.area ?? null}
                          onRefresh={() => {
                            dispatch(fetchDistributionAreaAction({}));
                          }}
                          onCreate={() => {
                            dispatch(
                              openModal(
                                <AreaCreateController
                                  callBack={(response) => {
                                    dispatch(addDistributionAreaData(response));
                                  }}
                                />,
                                "sm",
                                false,
                                "area"
                              )
                            );
                          }}
                          defaultVal={fields.area}
                          key={fields.type?._id}
                          onChange={(e, val) =>
                            setFields({ ...fields, area: val })
                          }
                          reducerDataObj={distributionArea}
                          titleKey="area_name"
                          valueKey="_id"
                          inputProps={{
                            label: "Area*",
                          }}
                          renderOption={(props, option) => (
                            <MenuItem {...props}>
                              {" "}
                              <ContactEmergency
                                fontSize="inherit"
                                color={
                                  option?.has_a_distributor ? "success" : "grey"
                                }
                              />{" "}
                              &nbsp;&nbsp;{option["area_name"]}
                            </MenuItem>
                          )}
                        />
                      </Grid>
                    )}

                  <Grid item xs={12} md={12}>
                    <GoogleAddress
                      defaultAddress={{
                        postCode: fields.pin,
                        state: fields.state,
                        locality: fields.city,
                        country: fields.country,
                      }}
                      onChange={(val) => {
                        setFields({
                          ...fields,
                          pin: val.postCode,
                          state: val.state,
                          city: val.locality,
                          country: val.country,
                        });
                      }}
                    />
                    <Box mb={2}></Box>
                  </Grid>

                  {fields?.type?.identifier &&
                    (fields.type?.identifier == ENQUIRY_TYPES_IDENTIFIER.CSR ||
                      fields.type?.identifier ==
                        ENQUIRY_TYPES_IDENTIFIER.DEALERSHIP ||
                      fields.type?.identifier ==
                        ENQUIRY_TYPES_IDENTIFIER.PURCHASED ||
                      fields.type?.identifier ==
                        ENQUIRY_TYPES_IDENTIFIER.INFORMATION) && (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={
                            fields.source?.identifier !=
                            ENQUIRY_SOURCES.REFERENCE
                              ? 12
                              : 6
                          }
                        >
                          <DynamicDataDropDown
                            fullWidth
                            defaultValue={fields.source}
                            key={fields.type?._id}
                            onChange={(e, val) =>
                              setFields({ ...fields, source: val })
                            }
                            reducerDataObj={source}
                            titleKey="name"
                            valueKey="_id"
                            inputProps={{
                              label: "Source Of Enquiry*",
                            }}
                          />
                        </Grid>
                        {fields.source?.identifier ==
                          ENQUIRY_SOURCES.REFERENCE && (
                          <>
                            <Grid item xs={12} md={3}>
                              <CustomInput
                                margin="none"
                                disabled={loading}
                                value={fields.referral_name}
                                onChange={(e) =>
                                  setFields({
                                    ...fields,
                                    err: "",
                                    referral_name: e.target.value,
                                  })
                                }
                                type="text"
                                label={"Referral Name"}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <CustomInput
                                margin="none"
                                disabled={loading}
                                value={fields.referral_phone}
                                onChange={(e) =>
                                  setFields({
                                    ...fields,
                                    err: "",
                                    referral_phone: e.target.value,
                                  })
                                }
                                type="text"
                                label={"Referral Phone No"}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}

                  {fields?.type?.identifier &&
                    (fields?.type?.identifier ===
                      ENQUIRY_TYPES_IDENTIFIER.INFORMATION ||
                      fields?.type?.identifier ===
                        ENQUIRY_TYPES_IDENTIFIER.PURCHASED) && (
                      <>
                        <Grid item xs={12} md={6}>
                          <ParentProductsList
                            fullWidth
                            defaultValue={defaulData?.product ?? null}
                            key={fields.type?._id}
                            onChange={(e, val) =>
                              setFields({ ...fields, product: val })
                            }
                          />
                        </Grid>
                      </>
                    )}

                  {fields?.product?.is_medical_product && (
                    <Grid item xs={12}>
                      <FileInput
                        sx={{ width: "100%" }}
                        onChange={(file) => {
                          setFields({ ...fields, audiogram: file });
                        }}
                        defaults={fields.audiogram ? [fields.audiogram] : []}
                        accept=".pdf"
                        title="Upload Audiogram"
                        subTitle="Only pdf files are allowed! less than 1 MB"
                      />
                    </Grid>
                  )}
                  {
                    <Grid item xs={12}>
                      <CustomInput
                        margin="none"
                        multiline
                        rows={3}
                        disabled={loading}
                        value={fields.remarks}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            err: "",
                            remarks: e.target.value,
                          })
                        }
                        type="text"
                        label={"Summary Of Call*"}
                      />
                    </Grid>
                  }
                </>
              )}
            </Grid>
          </Box>
        }
      </CustomDialog>
    </>
  );
};
export default memo(AddEnquiryUI);

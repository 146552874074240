import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import { Search, Warning } from "@mui/icons-material";
import { memo } from "react";
import {
  createFullName,
  findObjectKeyByValue,
  searchUniqueDataFromArray,
} from "../../utils/helpers/main";
import moment from "moment";
import { DEMO_LOCATION_TYPES, LEAD_LOG_TYPE } from "../../utils/constants/lead.constants";
import { findCustomerDetailsFromCallsArray } from "../../utils/helpers/lead.helper";
import LeadStatusComponent from "./LeadStatusComponent";
import FileDownloadComponent from "../../components/FileDownloadComponent";

const FlexedBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflow: "hidden",
}));
const Container = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
        height: "100%",
        overflow: "hidden",
        border: 1,
        borderColor: theme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.primary.main,
          background: theme.palette.primary.light,
        }}
        p={2}
      >
        {title}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          overflowY: "auto",
          background: theme.palette.dark[200],
        }}
        p={2}
      >
        {children}
      </Box>
    </Box>
  );
};
const CustomerDetailComponent = ({ title, value }) => {
  return (
    <Box sx={{ width: "100%" }} my={2}>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Typography variant="body1" fontWeight={600}>
            {title}:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="body1" fontWeight={400}>
            {value ?? "NA"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const CustomClickButton = ({ title, value, checked, ...props }) => {
  const theme = useTheme();
  return (
    <CenteredBox
      variant="outlined"
      sx={{
        minWidth: "100%",
        flex: 1,
        flexDirection: "column",
        border: 1,
        borderColor: theme.palette.primary.main,
        background: theme.palette.dark[200],
      }}
      {...props}
    >
      <Typography variant="body1" fontWeight={600}>
        {title}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </CenteredBox>
  );
};

const CustomerBasicDetailsView = memo(({ data }) => {
  const customerDetails = data;

  return (
    <Box sx={{ width: "100%" }}>
      <CustomerDetailComponent
        title="Name"
        value={createFullName(customerDetails)}
      />
      <CustomerDetailComponent title="Email" value={customerDetails?.email} />
      <CustomerDetailComponent
        title="Phone"
        value={customerDetails?.country_code + " " + customerDetails?.phone}
      />
      <CustomerDetailComponent
        title="Address"
        value={customerDetails?.address}
      />
    </Box>
  );
});


const LeadLogsComponent = ({ type, date, status, remarks, actionedBy }) => {
  return (
    <Paper variant="outlined" component={Box} p={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2">{date}</Typography>
        <Chip size="small" label={type} />
      </Box>

      <Box sx={{ display: "flex" }}>
        <Typography variant="subtitle2" fontWeight={600}>
          Status:&nbsp;{" "}
        </Typography>
        <Typography variant="subtitle2">
          <LeadStatusComponent params={{ status }} />
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography variant="caption" fontWeight={600}>
          Remarks:&nbsp;{" "}
        </Typography>
        <Typography variant="caption">{remarks}</Typography>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Typography variant="caption" fontWeight={600}>
          Actioned By:&nbsp;{" "}
        </Typography>
        <Typography variant="caption">{actionedBy}</Typography>
      </Box>
    </Paper>
  );
};
const LeadLogsView = memo(({ data }) => {
  const logs = data?.logs ?? [];
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }} px={2} py={1}>
        <Typography>Total: {logs?.length ?? 0}</Typography>
      </Box>
      {logs
        ?.sort?.((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())
        ?.map((item) => (
          <LeadLogsComponent
            key={item?._id}
            date={moment(item?.date).format("DD/MM/YYYY hh:mm")}
            status={item.lead_status}
            type={findObjectKeyByValue(item?.type, LEAD_LOG_TYPE)}
            actionedBy={item?.by?.name}
            remarks={item.remarks}
          />
        ))}
    </Box>
  );
});

const LeadDetailedView = ({ data, loading, onaddButtonClick }) => {
  const customerDetails = findCustomerDetailsFromCallsArray(
    data?.enquiries ?? []
  );
  if (loading)
    return (
      <CenteredBox width={"100%"}>
        <CircularProgress />
      </CenteredBox>
    );
  if (data["_id"])
    return (
      <>
        <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }} py={2}>
          <Box sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
            <Grid
              container
              spacing={2}
              sx={{ height: "100%", overflow: "hidden" }}
            >
              <Grid
                sx={{ height: "100%", overflow: "hidden" }}
                item
                xs={12}
                md={8}
              >
                <FlexedBox sx={{ height: "100%" }}>
                  <Box>
                    <Container title="Customer Details">
                      <CustomerBasicDetailsView data={data} />
                    </Container>
                  </Box>
                  <FlexedBox flex={1} mt={1}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ height: "100%", overflow: "hidden" }}
                    >
                      <Grid
                        sx={{ height: "100%", overflow: "hidden" }}
                        item
                        xs={12}
                        md={6}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <CustomClickButton
                            title={"Source"}
                            value={data?.source?.name ?? "NA"}
                          />
                          <CustomClickButton
                            title={"Communication Channel"}
                            value={
                              data?.communication_channel?.channel_name ?? "NA"
                            }
                          />
                          <CustomClickButton
                            title={"Distribution Area"}
                            value={data?.area?.area_name ?? "NA"}
                          />



                          {(data.referral_name || data.referral_phone) &&
                            (data.referral_name != "" ||
                              data.referral_phone != "") && (
                              <CustomClickButton
                                title={"Referral"}
                                value={
                                  (data?.referral_name ?? "") +
                                  " (" +
                                  (data?.referral_phone ?? "") +
                                  " )"
                                }
                              />
                            )}

                          {(data?.demo_details?.date) &&
                            (
                              <CustomClickButton
                                title={"Demo Details"}
                                value={
                                  moment(data?.demo_details?.date).format("DD/MM/YYYY") +
                                  " | " +
                                  (findObjectKeyByValue(data?.demo_details?.location_type, DEMO_LOCATION_TYPES) ?? "") +
                                  " | " + data?.demo_details?.location
                                }
                              />
                            )}
                          {data.dispatch_details?.box_id &&
                            data.dispatch_details?.box_id != "" && (
                              <CustomClickButton
                                title={"Dispatch Details"}
                                value={
                                  (data.dispatch_details?.box_id ?? "") +
                                  " (" +
                                  (data.dispatch_details?.payment_proof ? (
                                    <FileDownloadComponent
                                      src={data.dispatch_details?.payment_proof}
                                    />
                                  ) : (
                                    ""
                                  )) +
                                  " )"
                                }
                              />
                            )}
                        </Box>
                      </Grid>
                      <Grid
                        sx={{ height: "100%", overflow: "hidden" }}
                        item
                        xs={12}
                        md={6}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <CustomClickButton
                            title={"Status"}
                            value={
                              <LeadStatusComponent
                                params={{ status: data.status }}
                              />
                            }
                          />
                          <CustomClickButton
                            title={"Product"}
                            value={data?.products?.[0]?.product_name ?? "NA"}
                          />
                          {data?.is_medical_approval_needed && (
                            <CustomClickButton
                              title={"Suggested Products"}
                              value={
                                data?.suggested_products?.[0]?.product_name ??
                                "NA"
                              }
                            />
                          )}

                          {data?.is_medical_approval_needed && (
                            <CustomClickButton
                              title={"Audiogram"}
                              value={
                                data?.audiogram && data?.audiogram != "" ? (
                                  <FileDownloadComponent src={data.audiogram} />
                                ) : (
                                  "Audiogram not available"
                                )
                              }
                            />
                          )}

                          {data?.rejection_reason && data?.rejection_reason != '' && (
                            <CustomClickButton
                              title={"Rejection Reason"}
                              value={
                                data?.rejection_reason
                              }
                            />
                          )}
                          {data?.not_worked_products && data?.not_worked_products?.[0] && <CustomClickButton
                            title={"Tested Products"}
                            value={data?.not_worked_products?.map?.(item => item?.product_name)?.join?.(', ') ?? "NA"}
                          />}
                          {data?.purchased_products && data?.purchased_products?.[0] && <CustomClickButton
                            title={"Tested Products"}
                            value={data?.purchased_products?.map?.(item => `${item?.product?.product_name } ( ${item.amount} ) ` )?.join?.(', ') ?? "NA"}
                          />}
                        </Box>
                      </Grid>
                    </Grid>
                  </FlexedBox>
                </FlexedBox>
              </Grid>

              <Grid
                sx={{ height: "100%", overflow: "hidden" }}
                item
                xs={12}
                md={4}
              >
                <FlexedBox sx={{ overflow: "hidden", height: "100%" }} flex={1}>
                  <Container title="Lead Logs">
                    <LeadLogsView data={data} />
                  </Container>
                </FlexedBox>
              </Grid>
            </Grid>
          </Box>
          {/* */}
        </Box>
      </>
    );

  if (data.phone?.trim() == "")
    return (
      <CenteredBox flex={1}>
        <NoDataComponent Icon={Search} message="Search Phone No." />
      </CenteredBox>
    );

  return (
    <CenteredBox flex={1} flexDirection="column">
      <Box>
        <NoDataComponent Icon={Warning} message={`No Lead Found`} />
      </Box>
    </CenteredBox>
  );
};
export default LeadDetailedView;

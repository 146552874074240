import { CircularProgress } from "@mui/material";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import CustomInput from "../../components/inputs/CustomInput";
import { memo } from "react";

const AreaCreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  return (
    <>
      {
        <CustomDialog
          loading={loading}
          err={fields.err}
          onSubmit={onSubmit}
          title={`${isUpdate ? "Update" : "Create"} ${title}`}
          closeText="Close"
          confirmText={`${isUpdate ? "Update" : "Create"}`}
        >
          {loading ? (
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          ) : (
            <>
              {
                <CustomInput
                  autoFocus={true}
                  disabled={loading}
                  value={fields.area_name}
                  onChange={(e) =>
                    setFields({ ...fields, err: "", area_name: e.target.value })
                  }
                  type="text"
                  label={"Area Name*"}
                />
              }
            </>
          )}
        </CustomDialog>
        // )
      }
    </>
  );
};
export default memo(AreaCreateUi);

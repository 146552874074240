import { useState } from "react";
import PhoneSearchBar from "./PhoneSearchBar";
import CustomerDetailsController from "./CustomerDetailsController";

const { Box, Paper } = require("@mui/material");

const SearchCustomerMain = () => {
  const [phone, setPhone] = useState("");

  return (
    <Paper
      component={Box}
      variant="outlined"
      sx={{ height: "100%", overflow: "hidden" }}
      p={2}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <PhoneSearchBar
          onChange={(val) => {
            setPhone(val);
          }}
        />

        <CustomerDetailsController phone={phone} />
      </Box>
    </Paper>
  );
};
export default SearchCustomerMain;

import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/helpers/main";

export const getAllDistributionAreasApi = async params => {
  const callResponse = await axios({
    url: endpoints.areaAll,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getAreaApi = async params => {
    const callResponse = await axios({
      url: endpoints.areaBase,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
};

export const getAreaByIdApi = async params => {
    const callResponse = await axios({
      url: endpoints.areaByid,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };
  
  
  export const addAreaApi = async data => {
    const callResponse = await axios({
      url: endpoints.areaBase,
      method: "POST",
      headers: getHeaders(),
      
      data,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };
  
  export const updateAreaFieldApi = async data => {
    const callResponse = await axios({
      url: endpoints.areaBase,
      method: "PATCH",
      headers: getHeaders(),
      data,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };
  
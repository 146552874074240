import { getAllcommunicationChannelApi } from "../../apis/channel.api";
import { actions } from "../../utils/constants/main";
import { callApiAction } from "./commonAction";

export const fetchcommunicationChannelAction = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_COMMUNICATION_CHANNEL_LOADING });
    dispatch(
      callApiAction(
        async () => await getAllcommunicationChannelApi(data),
        (response) => {
          dispatch({
            type: actions.SET_COMMUNICATION_CHANNEL_DATA,
            data: response,
          });
          onSuccess(response);
        },
        (err) => {
          dispatch({ type: actions.CLEAR_COMMUNICATION_CHANNEL_DATA });
          onError(err);
        }
      )
    );
  };
};
export const addcommunicationChannelAata = (newDoc) => {
  return { type: actions.ADD_COMMUNICATION_CHANNEL_DATA, data: newDoc };
};
export const clearcommunicationChannelAata = (newDoc) => {
  return { type: actions.CLEAR_COMMUNICATION_CHANNEL_DATA };
};

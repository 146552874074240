import { memo, useEffect, useState } from "react";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchcommunicationChannelAction } from "../../store/actions/communicationChannelAction";
import { fetchSourceAction } from "../../store/actions/sourceAction";
import { fetchProductAction } from "../../store/actions/productAction";
import { fetchEnquiryTypeAction } from "../../store/actions/enquiryTypeAction";
import { fetchDistributionAreaAction } from "../../store/actions/distrubutionAreaAction";

const SetupController = ({ ...props }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {
    communicationChannel,
    product,
    distributionArea,
    source,
    enquiryType,
  } = useSelector((state) => state);
  useEffect(() => {
    dispatch(fetchcommunicationChannelAction({}));
    dispatch(fetchSourceAction({}));
    dispatch(fetchProductAction({}));
    dispatch(fetchEnquiryTypeAction({}));
    dispatch(fetchDistributionAreaAction({}));
  }, []);

  useEffect(() => {
    if (
      !communicationChannel.loading &&
      !product.loading &&
      !distributionArea.loading &&
      !source.loading &&
      !enquiryType.loading
    ) {
      setLoading(false);
    }
  }, [
    communicationChannel.loading,
    product.loading,
    distributionArea.loading,
    source.loading,
    enquiryType.loading,
  ]);
  if (loading)
    return (
      <CenteredBox>
        <NoDataComponent
          Icon={CircularProgress}
          message="Initializing App..."
        />
      </CenteredBox>
    );

  return <>{props.children}</>;
};

export default memo(SetupController);

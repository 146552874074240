import { getAllDistributionAreasApi } from "../../apis/area.api";
import { actions } from "../../utils/constants/main";
import { callApiAction } from "./commonAction";

export const fetchDistributionAreaAction = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_DISTRIBUTION_AREA_LOADING });
    dispatch(
      callApiAction(
        async () => await getAllDistributionAreasApi(data),
        (response) => {
          dispatch({
            type: actions.SET_DISTRIBUTION_AREA_DATA,
            data: response,
          });
          onSuccess(response);
        },
        (err) => {
          dispatch({ type: actions.CLEAR_DISTRIBUTION_AREA_DATA });
          onError(err);
        }
      )
    );
  };
};
export const addDistributionAreaData = (newDoc) => {
  return { type: actions.ADD_DISTRIBUTION_AREA_DATA, data: newDoc };
};
export const clearDistributionAreaData = (newDoc) => {
  return { type: actions.CLEAR_DISTRIBUTION_AREA_DATA };
};

import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import {
  addProductApi,
  getProductByIdApi,
  updateProductField,
} from "../../apis/product.api";

const CreateController = ({ callBack, id }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();

  const title = "Products ";
  const createApi = addProductApi;
  const updateApi = updateProductField;
  const getByIdApi = getProductByIdApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    product_name: "",
    mrp: "",
    file_url: "",
    priority: "",
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.product_name,
        field: "Product Name",
      },
      {
        required: true,
        value: fields.mrp,
        field: "MRP",
      },
      // {
      //   required: true,
      //   value: fields.file_url,
      //   field: "Upload Brochure",
      // },
      {
        required: true,
        value: fields.priority,
        field: "Priority",
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.product_name,
        field: "Product Name",
      },
      {
        required: true,
        value: fields.mrp,
        field: "MRP",
      },
      // {
      //   required: true,
      //   value: fields.file_url,
      //   field: "Upload Brochure",
      // },
      {
        required: true,
        value: fields.priority,
        field: "Priority",
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);
    const dataToBeCreate = {}
    for (let field in fields){
      if (typeof fields[field] == "object" && !Array.isArray(fields[field])) {
        dataToBeCreate[field] = fields[field]?._id
      } else
        dataToBeCreate[field] = fields[field]}

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(dataToBeCreate),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id };

    for (let field in fields) {
      if (fields[field] != originalDocument[field]) {
        if (typeof fields[field] == "object" && !Array.isArray(fields[field])) {
          updatedData[field] = fields[field]?._id;
        }else
        updatedData[field] = fields[field];
      }
    }

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);

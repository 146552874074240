import { Box, Paper, Typography } from "@mui/material";
import SubmitButton from "../../components/button/SubmitButton";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import DataTable from "../../components/tables/DataTable";
import styled from "@emotion/styled";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const AreaUi = ({
  title,
  filters,
  setFilters,
  list,
  loading,
  onCreateBtnClick,
  columns,
}) => {
  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Box mb={2}>
                <SubmitButton
                  variant="contained"
                  onClick={onCreateBtnClick}
                  title={"Add Area"}
                />
              </Box>
            </FilterTitleBox>

            <FiltersBox mt={3}>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
                pl={3}
              >
                <AsynSearchBar
                  fullWidth
                  title="Search By Area Name "
                  size="small"
                  placeholder={"Search By Area Name "}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                />
                {/* <StyledSearchBar  title="Search By Key Name " size="small" placeholder={"Search By key Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
              </PaddingBoxInDesktop>
            </FiltersBox>
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default AreaUi;

import {
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import { PersonSearch, Search, Warning } from "@mui/icons-material";
import { memo, useState } from "react";
import {
  createFullName,
  findObjectKeyByValue,
  searchUniqueDataFromArray,
} from "../../utils/helpers/main";
import moment from "moment";
import { LEAD_STATUS } from "../../utils/constants/lead.constants";
import {
  COMPLAIN_STATUS,
  CSR_STATUS,
  DEALERSHIP_STATUS,
} from "../../utils/constants/main";
import { findCustomerDetailsFromCallsArray } from "../../utils/helpers/lead.helper";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import LeadDetailedViewController from "../lead/LeadDetailedViewController";
import { AddAudiogramButton } from "../lead/LeadStatusUpdateButtons";

const FlexedBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflow: "hidden",
}));
const Container = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
        height: "100%",
        overflow: "hidden",
        border: 1,
        borderColor: theme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.primary.main,
          background: theme.palette.primary.light,
        }}
        p={2}
      >
        {title}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          overflowY: "auto",
          background: theme.palette.dark[200],
        }}
        p={2}
      >
        {children}
      </Box>
    </Box>
  );
};
const CustomerDetailComponent = ({ title, value }) => {
  return (
    <Box sx={{ width: "100%" }} my={2}>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Typography variant="body1" fontWeight={600}>
            {title}:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="body1" fontWeight={400}>
            {value ?? "NA"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const CustomClickButton = ({ title, value, checked, ...props }) => {
  return (
    <Button
      fullWidth
      sx={{ flexDirection: "column" }}
      variant={checked ? "contained" : "outlined"}
      {...props}
    >
      <Typography variant="h6">{value}</Typography>
      <Typography variant="caption">{title}</Typography>
    </Button>
  );
};

const CustomerBasicDetailsView = memo(({ data }) => {
  const customerDetails = findCustomerDetailsFromCallsArray(
    data?.enquiries ?? []
  );

  return (
    <Box sx={{ width: "100%" }}>
      <CustomerDetailComponent
        title="Name"
        value={createFullName(customerDetails)}
      />
      <CustomerDetailComponent title="Email" value={customerDetails?.email} />
      <CustomerDetailComponent
        title="Phone"
        value={customerDetails?.country_code + " " + customerDetails?.phone}
      />
      <CustomerDetailComponent
        title="Address"
        value={customerDetails?.address}
      />
    </Box>
  );
});
const OtherDetailsView = memo(({ data }) => {
  const enquiryArray = data?.enquiries;

  return (
    <Box sx={{ width: "100%" }}>
      <CustomerDetailComponent
        title="Sources"
        value={searchUniqueDataFromArray(enquiryArray, "source", "_id")
          ?.map((item) => item.name)
          ?.join(", ")}
      />
      <CustomerDetailComponent
        title="Distribution Area"
        value={searchUniqueDataFromArray(enquiryArray, "area", "_id")
          ?.map((item) => item.area_name)
          ?.join(", ")}
      />
    </Box>
  );
});
const LeadHistoryComponent = ({ id, date, status, source, product, isMedicalProduct, callBack }) => {
  const dispatch = useDispatch();
  const onLeadButtonClick = () => {
    dispatch(
      openModal(
        <LeadDetailedViewController id={id} />,
        "md",
        undefined,
        "lead-details"
      )
    );
  };
  return (
    <Paper variant="outlined" p={2} component={Box}>
      <ButtonBase component={Box} onClick={onLeadButtonClick} sx={{ flexDirection: "column", width: "100%", justifyContent: "flex-start", alignItems: "flex-start" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2">{date}</Typography>
          <Chip
            size="small"
            label={findObjectKeyByValue(status, LEAD_STATUS)}
          />
        </Box>

        <Box sx={{ display: "flex" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Source:&nbsp;{" "}
          </Typography>
          <Typography variant="subtitle2">{source}</Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex" }} >
            <Typography variant="subtitle2" fontWeight={600}>
              Product:&nbsp;{" "}
            </Typography>
            <Typography variant="subtitle2">{product}</Typography>
          </Box>

        </Box>
      </ButtonBase>
      <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} >
        <AddAudiogramButton status={status} id={id} isMedicalProduct={isMedicalProduct} />
      </Box>
    </Paper>

  );
};
const LeadHistoryView = memo(({ data, callBack }) => {
  const leads = data?.leads ?? [];
  if (leads?.length <= 0)
    return (
      <CenteredBox sx={{ width: "100%" }}>
        <Typography>No Leads Found</Typography>
      </CenteredBox>
    );
  return (
    <>
      <Box sx={{ width: "100%" }}>
        {leads?.map?.((item) => (
          <LeadHistoryComponent
            callBack={callBack}
            key={item?._id}
            id={item?._id}
            product={item?.products?.[0]?.product_name}
            isMedicalProduct={item?.products?.[0]?.is_medical_product}
            source={item?.source?.name}
            date={moment(item.createdAt).format("DD/MM/YYYY hh:mm")}
            status={item.status}
          />
        ))}
      </Box>
    </>
  );
});

const HistoryComponentGeneral = ({ date, status, remarks }) => {
  return (
    <Paper variant="outlined" component={Box} p={2} mb={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2">{date}</Typography>
        <Chip size="small" label={status} />
      </Box>

      <Box sx={{ display: "flex" }}>
        <Typography variant="subtitle2" fontWeight={600}>
          Remarks:&nbsp;{" "}
        </Typography>
        <Typography variant="subtitle2">{remarks}</Typography>
      </Box>
    </Paper>
  );
};
const ComplainHistoryView = memo(({ data }) => {
  const complains = data?.complains ?? [];
  if (complains?.length <= 0)
    return (
      <CenteredBox sx={{ width: "100%" }}>
        <Typography>No Complains Found</Typography>
      </CenteredBox>
    );
  return (
    <>
      <Box sx={{ width: "100%" }}>
        {complains?.map?.((item) => (
          <HistoryComponentGeneral
            key={item?._id}
            date={moment(item.createdAt).format("DD/MM/YYYY hh:mm")}
            status={findObjectKeyByValue(item.status, COMPLAIN_STATUS)}
            remarks={item.complain}
          />
        ))}
      </Box>
    </>
  );
});

const DealershipHistoryView = memo(({ data }) => {
  const dealerships = data?.dealerships ?? [];
  if (dealerships?.length <= 0)
    return (
      <CenteredBox sx={{ width: "100%" }}>
        <Typography>No Dealerships Found</Typography>
      </CenteredBox>
    );
  return (
    <>
      <Box sx={{ width: "100%" }}>
        {dealerships?.map?.((item) => (
          <HistoryComponentGeneral
            key={item?._id}
            date={moment(item.createdAt).format("DD/MM/YYYY hh:mm")}
            status={findObjectKeyByValue(item.status, DEALERSHIP_STATUS)}
            remarks={item.remarks}
          />
        ))}
      </Box>
    </>
  );
});
const CSRHistoryView = memo(({ data }) => {
  const csrs = data?.csrs ?? [];
  if (csrs?.length <= 0)
    return (
      <CenteredBox sx={{ width: "100%" }}>
        <Typography>No CSR Found</Typography>
      </CenteredBox>
    );
  return (
    <>
      <Box sx={{ width: "100%" }}>
        {csrs?.map?.((item) => (
          <HistoryComponentGeneral
            key={item?._id}
            date={moment(item.createdAt).format("DD/MM/YYYY hh:mm")}
            status={findObjectKeyByValue(item.status, CSR_STATUS)}
            remarks={item.remarks}
          />
        ))}
      </Box>
    </>
  );
});

const EnquiriesBifurcationView = ({ data, callBack }) => {
  const [tab, setTab] = useState(0);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} lg={3}>
          <CustomClickButton
            checked={tab == 0}
            onClick={() => setTab(0)}
            title={"Leads"}
            value={data?.leads?.length ?? 0}
          />
        </Grid>
        <Grid item md={6} lg={3}>
          <CustomClickButton
            checked={tab == 1}
            onClick={() => setTab(1)}
            title={"Complains"}
            value={data?.complains?.length ?? 0}
          />
        </Grid>
        <Grid item md={6} lg={3}>
          <CustomClickButton
            checked={tab == 2}
            onClick={() => setTab(2)}
            title={"Dealership"}
            value={data?.dealerships?.length ?? 0}
          />
        </Grid>
        <Grid item md={6} lg={3}>
          <CustomClickButton
            checked={tab == 3}
            onClick={() => setTab(3)}
            title={"CSR"}
            value={data?.csrs?.length ?? 0}
          />
        </Grid>
      </Grid>

      <FlexedBox sx={{ overflow: "hidden" }} flex={1} mt={2}>
        {tab == 0 && (
          <Container title="Leads">
            <LeadHistoryView data={data} callBack={callBack} />
          </Container>
        )}
        {tab == 1 && (
          <Container title="Complains">
            <ComplainHistoryView data={data} />
          </Container>
        )}
        {tab == 2 && (
          <Container title="Dealerships">
            <DealershipHistoryView data={data} />
          </Container>
        )}
        {tab == 3 && (
          <Container title="CSR">
            <CSRHistoryView data={data} />
          </Container>
        )}
      </FlexedBox>
    </>
  );
};

const EnquiryHistoryComponent = ({
  type,
  date,
  source,
  remarks,
  attended_by,
  communication_channel,
}) => {
  return (
    <Paper variant="outlined" component={Box} p={2} mb={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2">{date}</Typography>
        <Chip size="small" label={type} />
      </Box>

      <Grid container>
        <Grid item xs={6}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="subtitle2" fontWeight={600}>
              Source:&nbsp;{" "}
            </Typography>
            <Typography variant="subtitle2">{source}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="subtitle2" fontWeight={600}>
              Channel:&nbsp;{" "}
            </Typography>
            <Typography variant="subtitle2">{communication_channel}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex" }}>
        <Typography variant="caption" fontWeight={600}>
          Remarks:&nbsp;{" "}
        </Typography>
        <Typography variant="caption">{remarks}</Typography>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Typography variant="caption" fontWeight={600}>
          Attended By:&nbsp;{" "}
        </Typography>
        <Typography variant="caption">{attended_by}</Typography>
      </Box>
    </Paper>
  );
};
const EnquiryHistoryView = memo(({ data }) => {
  const enquiries = data?.enquiries ?? [];
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }} px={2} py={1}>
        <Typography>Total: {enquiries?.length ?? 0}</Typography>
      </Box>
      {enquiries?.map((item) => (
        <EnquiryHistoryComponent
          key={item?._id}
          date={moment(item?.createdAt).format("DD/MM/YYYY hh:mm")}
          source={item?.source?.name}
          type={item?.type?.name}
          attended_by={item?.attended_by?.name}
          communication_channel={item?.communication_channel?.channel_name}
          remarks={item.remarks}
        />
      ))}
    </Box>
  );
});

const CustomerDetailedView = ({ data, loading, onaddButtonClick, callBack }) => {
  const customerDetails = findCustomerDetailsFromCallsArray(
    data?.enquiries ?? []
  );
  if (loading)
    return (
      <CenteredBox flex={1}>
        <CircularProgress />
      </CenteredBox>
    );
  if (data["data_exist"])
    return (
      <>
        <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }} py={2}>
          <Box sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
            <Grid
              container
              spacing={2}
              sx={{ height: "100%", overflow: "hidden" }}
            >
              <Grid
                sx={{ height: "100%", overflow: "hidden" }}
                item
                xs={12}
                md={4}
              >
                <FlexedBox sx={{ height: "100%" }}>
                  <FlexedBox flex={1}>
                    <Container title="Customer Details">
                      <CustomerBasicDetailsView data={data} />
                    </Container>
                  </FlexedBox>
                  <FlexedBox flex={1} mt={1}>
                    <Container title="Other Details">
                      <OtherDetailsView data={data} />
                    </Container>
                  </FlexedBox>
                </FlexedBox>
              </Grid>
              <Grid
                sx={{ height: "100%", overflow: "hidden" }}
                item
                xs={12}
                md={4}
              >
                <FlexedBox sx={{ height: "100%" }}>
                  <EnquiriesBifurcationView data={data} callBack={callBack} />
                </FlexedBox>
              </Grid>
              <Grid
                sx={{ height: "100%", overflow: "hidden" }}
                item
                xs={12}
                md={4}
              >
                <FlexedBox sx={{ height: "100%" }}>
                  <Button
                    onClick={() => {
                      onaddButtonClick({
                        disableAvailableData: true,
                        ...customerDetails,
                        channel: customerDetails?.communication_channel ?? null,
                        product: customerDetails?.products?.[0] ?? null,
                      });
                    }}
                    sx={{ py: 3 }}
                    variant="contained"
                    disableElevation
                  >
                    Add Another Enquiry
                  </Button>
                  <FlexedBox sx={{ overflow: "hidden" }} flex={1} mt={3}>
                    <Container title="Enquiry Logs">
                      <EnquiryHistoryView data={data} />
                    </Container>
                  </FlexedBox>
                </FlexedBox>
              </Grid>
            </Grid>
          </Box>
          {/* */}
        </Box>
      </>
    );

  if (data.phone?.trim() == "")
    return (
      <CenteredBox flex={1}>
        <NoDataComponent Icon={Search} message="Search Phone No." />
      </CenteredBox>
    );

  return (
    <CenteredBox flex={1} flexDirection="column">
      <Box>
        <NoDataComponent
          Icon={PersonSearch}
          message={`No Enquiry From "${data.phone}"`}
        />
      </Box>
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        onClick={() => {
          onaddButtonClick();
        }}
      >
        Add New Enquiry
      </Button>
    </CenteredBox>
  );
};
export default CustomerDetailedView;

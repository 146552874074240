import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getAllProductApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.productAll,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getProductApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.productFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getProductByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.productFetchByid,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addProductApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.productBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateProductField = async (data) => {
  const callResponse = await axios({
    url: endpoints.productUpdate,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export default getProductApi;

import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getSecretsApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.secretsBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getSecretsByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.secretsById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addSecretsApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.secretsBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateSecretsField = async (data) => {
  const callResponse = await axios({
    url: endpoints.secretsBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

// export const deleteSecretsApi = async data => {
//   const callResponse = await axios({
//     url: endpoints.secretsBase,
//     method: "DELETE",
//     headers: getHeaders(),
//     data,
//   })
//     .then(response => response.data)
//     .catch(err => err.response.data);

//   return callResponse;
// };
export default getSecretsApi;

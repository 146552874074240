import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import {
  addUserApi,
  getUserByIdApi,
  updateInstallerUserField,
  updateUserField,
} from "../../apis/user.api";
import { closeModal } from "../../store/actions/modalAction";
import { USER_ROLES } from "../../utils/constants/main";

const CreateController = ({ callBack, id, isInstaller }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const title = isInstaller ? "Installer " : "User ";
  const createApi = addUserApi;
  const updateApi = isInstaller ? updateInstallerUserField : updateUserField;
  const getByIdApi = getUserByIdApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    name: "",
    email: "",
    phone: null,
    password: "",
    mail_pass: "",

    role:
      user.data.role == USER_ROLES.DISTRIBUTOR
        ? 3
        : isInstaller
        ? USER_ROLES.installer
        : null,
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Name",
      },

      {
        required: true,
        value: fields.email,
        field: "Email",
        isEmail: true,
      },
      {
        required: true,
        value: fields.phone,
        field: "Phone no ",
      },
      {
        required: true,
        value: fields.password,
        field: "Password",
      },

      {
        required: true,
        value: fields.role,
        field: "Role",
      },

      {
        value: fields.area,
        field: "",
        custom: () => {
          if (
            user.data.role != USER_ROLES.DISTRIBUTOR &&
            (fields.role == USER_ROLES.DISTRIBUTOR ||
              fields.role == USER_ROLES.sales) &&
            (!fields.area || fields.area == "")
          ) {
            return "Area is required";
          }
          return true;
        },
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Name",
      },

      {
        value: fields.area,
        field: "",
        custom: () => {
          if (
            user.data.role != USER_ROLES.DISTRIBUTOR &&
            (fields.role == USER_ROLES.DISTRIBUTOR ||
              fields.role == USER_ROLES.sales) &&
            (!fields.area || fields.area == "")
          ) {
            return "Area is required";
          }
          return true;
        },
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id };

    for (let field in fields) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
      if (!originalDocument[field]) {
        updatedData[field] = fields[field];
      }
    }
    updatedData["mail_pass"] = fields["mail_pass"];
    updatedData["phone"] = fields["phone"];

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id, isInstaller }),
        async (response) => {
          setFields({ ...fields, ...response });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      isInstaller={isInstaller}
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);

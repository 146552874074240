import { memo } from "react";
import {
  ReferralStatusColor,
  findObjectKeyByValue,
} from "../../utils/helpers/main";
import { Chip } from "@mui/material";
import { REFERRAL_STATUS } from "../../utils/constants/main";

const ReferralStatusComponent = memo(({ params, setParams }) => {
  return (
    <Chip
      size="small"
      color={ReferralStatusColor(params.status)}
      label={findObjectKeyByValue(params.status, REFERRAL_STATUS)}
    />
  );
});
export default ReferralStatusComponent;

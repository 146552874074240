import {
  COMPLAIN_STATUS,
  CSR_STATUS,
  DEALERSHIP_STATUS,
  REFERRAL_STATUS,
  USER_ROLES,
} from "../constants/main";
import moment from "moment";

export const accessToken = {
  set: (val) => {
    localStorage.setItem("wehear_lead_management_access_token", val);
  },
  get: () => localStorage.getItem("wehear_lead_management_access_token"),
  remove: () => {
    localStorage.removeItem("wehear_lead_management_access_token");
  },
};
export const loggedInUser = {
  set: (val) => {
    localStorage.setItem("wehear_lead_management_user", JSON.stringify(val));
  },
  get: () =>
    localStorage.getItem("wehear_lead_management_user")
      ? JSON.parse(localStorage.getItem("wehear_lead_management_user"))
      : null,
  remove: () => {
    localStorage.removeItem("wehear_lead_management_user");
  },
};

export const refreshToken = {
  set: (val) => {
    localStorage.setItem("wehear_lead_management_refresh_token", val);
  },
  get: () => localStorage.getItem("wehear_lead_management_refresh_token"),
  remove: () => {
    localStorage.removeItem("wehear_lead_management_refresh_token");
  },
};

export const lastReadNotification = {
  set: (val) => {
    localStorage.setItem("last_notification", val);
  },
  get: () =>
    localStorage.getItem("last_notification")
      ? localStorage.getItem("last_notification")
      : null,
  remove: () => {
    localStorage.removeItem("last_notification");
  },
};
export const toTitleCase = (str) => {
  if (str)
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

  return null;
};

export const toTitleSpaceCase = (str) => {
  if (str)
    return str.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  return str;
};

export const chatgroupsLastReadMessages = {
  set: (groupId, messagId, messageTime) => {
    const stringData = localStorage.getItem(
      "wehear_lead_management_last_read_messages"
    );
    const data = stringData ? JSON.parse(stringData) : {};

    data[groupId] = {
      messagId: messagId ?? null,
      messageTime: messageTime ?? null,
    };

    localStorage.setItem(
      "wehear_lead_management_last_read_messages",
      JSON.stringify(data)
    );
  },
  get: (groupId) => {
    const stringData = localStorage.getItem(
      "wehear_lead_management_last_read_messages"
    );
    const data = stringData ? JSON.parse(stringData) : {};
    return data[groupId] ?? null;
  },
  remove: () => {
    localStorage.removeItem("wehear_lead_management_last_read_messages");
  },
};
export const logOut = () => {
  refreshToken.remove();
  accessToken.remove();
  loggedInUser.remove();
  lastReadNotification.remove();
  localStatecallModal().remove();
};

export const getHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;
  return {
    Authorization: token,
  };
};
export const getFileHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;
  return {
    Authorization: token,
    "Content-Type": "multipart/form-data",
  };
};
export function setZeroPrefix(val) {
  if (parseInt(val, 10) < 10) {
    return `0${val}`;
  }
  return val;
}

export const dateConverter = ({ type = "DD_MM_YYYY", value }) => {
  if (type === "DD_MM_YYYY") {
    const dateObj = new Date(value);
    return `${setZeroPrefix(dateObj.getDate())}/${setZeroPrefix(
      dateObj.getMonth() + 1
    )}/${dateObj.getFullYear()}`;
  }
  return value;
};

export const validateEmail = (email) => {
  var re =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};
export const validatePhone = (phone) => {
  var re = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  return phone.match(re);
};
export const allowedDateFilters = [
  {
    id: "today",
    label: "Today",
  },
  {
    id: "yesterday",
    label: "Yesterday",
  },
  {
    id: "last_week",
    label: "Last Week",
  },
  {
    id: "last_month",
    label: "Last Month",
  },
  {
    id: "last_year",
    label: "Last Year",
  },
  {
    id: "this_week",
    label: "This Week",
  },

  {
    id: "this_month",
    label: "This Month",
  },

  {
    id: "this_year",
    label: "This Year",
  },
];
export const getDateFiltersTime = (value) => {
  let date = new Date();
  let startDate = date.getTime();
  let endDate = date.getTime();

  switch (value) {
    case "this_week":

      startDate = moment().startOf("week").valueOf();

      break;
    case "this_month":

      // const temp = new Date(startDate)
      // startDate = new Date(temp.getFullYear(), temp.getMonth()).getTime()
      startDate = moment().startOf("month").valueOf();

      break;
    case "this_year":

      startDate = moment().startOf("year").valueOf();

      break;

    case "last_month":

      startDate = moment().add(-1, "month").startOf("month").valueOf();
      endDate = moment().add(-1, "month").endOf("month").valueOf();

      break;
    case "last_year":

      startDate = moment().add(-1, "year").startOf("year").valueOf();
      endDate = moment().add(-1, "year").endOf("year").valueOf();

      break;
    case "last_week":

      startDate = moment().add(-1, "week").startOf("week").valueOf();
      endDate = moment().add(-1, "week").endOf("week").valueOf();

      break;
    case "today":

      startDate = moment().startOf("day").valueOf();
      endDate = moment().endOf("day").valueOf();

      break;
    case "yesterday":

      startDate = moment().add(-1, "day").startOf("day").valueOf();
      endDate = moment().add(-1, "day").endOf("day").valueOf();

      break;
    case "past":

      endDate = new Date(endDate);

      endDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate() + 1
      ).getTime();

      startDate = null;

      break;
    case "future":

      startDate = new Date(startDate);

      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      ).getTime();

      endDate = null;

      break;

    default:
      startDate = null;
      endDate = null;

  }

  return {
    startDate,
    endDate,
  };
};
export const titleCase = (s) => {
  if (s)
    return s
      .toLowerCase()
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

  return null;
};

export const findNameByRole = (role) => {
  for (let val in USER_ROLES) {
    if (USER_ROLES[val] == role) {
      return titleCase(val);

    }
  }
  return undefined;
};
export const findObjectKeyByValue = (value, object) => {
  for (let val in object) {
    if (object[val] == value) {
      return titleCase(val);

    }
  }
  return undefined;
};
//------------------------------------------------------------------------------------------

export const fetchAllowedStatusForComplain = (currentStatus) => {
  switch (currentStatus) {
    case null:
      return Object.values(COMPLAIN_STATUS);
    case COMPLAIN_STATUS.PENDING:
      return [COMPLAIN_STATUS.PENDING];
    case COMPLAIN_STATUS.COMPLETE:
      return [COMPLAIN_STATUS.COMPLETE];

    default:
      return Object.values(COMPLAIN_STATUS);
  }
};

export const fetchAllowedComplainSubTab = (tab) => {
  switch (tab) {
    case 1:
      return [
        {
          title: "Pending",
          value: COMPLAIN_STATUS.PENDING,
        },
        {
          title: "Complete",
          value: COMPLAIN_STATUS.COMPLETE,
        },
      ];

    default:
      return null;
  }
};

export const ComplainStatusColor = (currentStatus) => {
  switch (parseInt(currentStatus)) {
    case CSR_STATUS.PENDING:
      return "error";
    case CSR_STATUS.COMPLETE:
      return "success";

    default:
      return "primary";
  }
};

export const CSRStatusColor = (currentStatus) => {
  switch (parseInt(currentStatus)) {
    case COMPLAIN_STATUS.PENDING:
      return "error";
    case COMPLAIN_STATUS.COMPLETE:
      return "success";

    default:
      return "primary";
  }
};

export const DealershipStatusColor = (currentStatus) => {
  switch (parseInt(currentStatus)) {
    case DEALERSHIP_STATUS.PENDING:
      return "error";
    case DEALERSHIP_STATUS.COMPLETE:
      return "success";

    default:
      return "primary";
  }
};

export const ReferralStatusColor = (currentStatus) => {
  switch (parseInt(currentStatus)) {
    case REFERRAL_STATUS.PENDING:
      return "error";
    case REFERRAL_STATUS.DISCOUNT_GIVEN:
      return "success";

    default:
      return "primary";
  }
};

export const localStatecallModal = () => {
  return {
    set: (val) => localStorage.setItem("call_modal_state", JSON.stringify(val)),
    get: () =>
      localStorage.getItem("call_modal_state")
        ? JSON.parse(localStorage.getItem("call_modal_state"))
        : null,
    remove: () => localStorage.removeItem("call_modal_state"),
  };
};
export const fetchFileName = (str) => {
  if (str) {
    const split = str.split("/");
    return split[split.length - 1];
  }
  return "";
};
export const createAddress = (data) => {
  return [data?.address, data?.city, data?.state, data?.country, data?.postcode]
    .filter((item) => item && item != "")
    .join(", ");
};
export const createFullName = (data) => {
  return [data?.first_name, data?.middle_name, data?.last_name]
    .filter((item) => item && item != "")
    .join(" ");
};
export function validatePhoneNumber(phoneNumber) {
  // Define a regular expression for a 10-digit phone number starting with 0
  var pattern = /^0\d{9}$/;

  // Use the test method to check if the phone number matches the pattern
  return pattern.test(phoneNumber);
}

export const searchUniqueDataFromArray = (
  arr = [],
  searchKey = "",
  mainKey = "_id"
) => {
  const obj = {};
  arr.forEach((item) => {
    const value = item?.[searchKey]?.[mainKey];
    if (value) {
      obj[value] = item[searchKey];
    }
  });
  return Object.values(obj);
};

import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { searchCustomerDetaislUsingPhoneApi } from "../../apis/customer.api";
import CustomerDetailedView from "./CustomerDetailViewUi";
import { openModal } from "../../store/actions/modalAction";
import AddEnquiryController from "../enquiry/AddEnquiryController";
import { useCallback } from "react";

const CustomerDetailsController = ({ phone }) => {
  const dispatch = useDispatch();
  const getByIdApi = searchCustomerDetaislUsingPhoneApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    phone,
  });

  const fetchById = useCallback((phone) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ phone }),
        async (response) => {
          setFields({ ...fields, ...response, phone });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err, phone });
          setLoading(false);
        }
      )
    );
  },[dispatch]);
  const onaddButtonClick = (defaultData = {}) => {
    dispatch(
      openModal(
        <AddEnquiryController
          defaulData={{ phone, ...defaultData }}
          callBack={() => fetchById(phone)}
        />,
        "md",
        true,
        "add-enquiry"
      )
    );
  };
  useEffect(() => {
    fetchById(phone);
  }, [phone]);

  return (
    <CustomerDetailedView
    callBack={fetchById}
      onaddButtonClick={onaddButtonClick}
      data={fields}
      loading={loading}
    />
  );
};
export default memo(CustomerDetailsController);

import { Close, Download } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, Fade, IconButton, Modal, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { getFile } from "../apis/file.api";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import fileDownload from "js-file-download";

const ImageComponent = ({ src, alt, sx = {}, noClickEffect, isStatic, ...props }) => {
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const [fileData,setFile] = useState(null)
    const handleOpen = () => {
        if (!noClickEffect) {
            // setLoading(true)
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    const fetchImage = async () => {
        const file = await getFile(src)
        setFile(file)
        setImage(URL.createObjectURL(file))

        setLoading(false)
    }
    const [img, setImage] = useState(isStatic ? src : null)

    useEffect(() => {
        if (!isStatic)
            fetchImage()
    }, [src])

    return (

        <> <Dialog

            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            fullWidth
        >
            <DialogTitle sx={{ justifyContent: "flex-end", display: "flex" }}>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ overflow: "hidden", display: "flex" }} >


                <Box sx={{ width: "100%", maxHieght: "100%", background: "lightgray", position: "relative" }}>
                    <TransformWrapper
                        smooth
                        style={{ height: "100%", width: "100%" }}
                        initialScale={1}
                    >
                        <TransformComponent style={{ height: "100%", width: "100%" }}>

                            <img onLoad={() => { setLoading(false) }} src={img} alt={alt} style={{ width: "100%", height: "100%", objectFit: "contain" }} {...props} />

                        </TransformComponent>
                    </TransformWrapper>
                    <Box  sx={{ position: "absolute", top: "10px", right: "10px",background:"white",borderRadius:"100px" }}>
                        <IconButton onClick={()=>{
                            
                            fileDownload(fileData,src)
                        }} disableElevation variant="contained" fullWidth >
                            <Download />
                        </IconButton>
                    </Box>
                </Box>

            </DialogContent>
        </Dialog>

            <Box sx={{ width: "100%", position: "relative", height: "100%", minHeight: "10px" }} >
                {loading && <Box sx={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%"}}>
                    <Skeleton sx={{ height: "100%", width: "100%" }} animation="wave" variant="rounded" >

                    </Skeleton>
                </Box>}

                <img src={img} onLoad={() => { setLoading(false) }} alt={alt} style={{ objectFit: "cover", width: "100%", height: "100%", ...sx, cursor: noClickEffect ? "default" : "pointer" }} {...props} onClick={handleOpen} />

            </Box>

        </>)
}
export default ImageComponent
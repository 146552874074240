import { combineReducers } from "@reduxjs/toolkit";
import modalReducer from "./modalReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import snackBarReducer from "./snackbarReducer";
import chatReducer from "./chatReducer";
import communicationChannelReducer from "./communicationChannelReducer";
import productReducer from "./productReducer";
import distributionAreaReducer from "./distributionAreaReducer";
import sourceReducer from "./sourceReducer";
import enquiryTypeReducer from "./enquiryTypeReducer";

const rootReducer = combineReducers({
  user: userReducer,
  modal: modalReducer,
  notifications: notificationReducer,
  chat: chatReducer,
  snackBar: snackBarReducer,
  communicationChannel: communicationChannelReducer,
  product: productReducer,
  distributionArea: distributionAreaReducer,
  source: sourceReducer,
  enquiryType: enquiryTypeReducer,
});
export default rootReducer;

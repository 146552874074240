import { useEffect, useRef, useState } from "react";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import { Box, Button, Grow, Menu, MenuItem, MenuList, Paper, Popper, Slide, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import { useDispatch } from "react-redux";
import { getCustomerSuggetionListApi } from "../../apis/customer.api";
import { callApiAction } from "../../store/actions/commonAction";

const PhoneSearchBar = ({ onChange }) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch()
  const ref = useRef(null)

  const [anchorEl, setAnchorEl] = useState(null);
  const [focused, setFocused] = useState(false);
  const [list, setList] = useState([])
  const fetchFun = () => {
    dispatch(
      callApiAction(
        async () => await getCustomerSuggetionListApi({ search: value }),
        (response) => {
          setList(response);

        },
        (err) => {

        }
      )
    );
  }
  const openPopOver = (event) => {
    if (!anchorEl)
      setAnchorEl(event.currentTarget);
  };
  const closePopover = (event) => {
    setAnchorEl(null);
  };
  const onSelect = (e, val) => {
    e?.preventDefault();

    ref.current.changeVal(val)
    onChange(val);

    closePopover()


  };
  const onSubmit = (e) => {
    e?.preventDefault();
    setAnchorEl(null)
    onChange(value);

  };
  const open = Boolean(anchorEl) && value.length > 0 && list.length > 0;
  const id = open ? 'simple-popper' : undefined;
  useEffect(() => {
    if (value && value.toString() != '') {
      fetchFun()
    } else {
      setList([])
    }
  }, [value])

  useEffect(() => {
    if (open) {
      let event = document.addEventListener("keydown", (e) => {
        if (e.key == "s") {

        }
      })
    }
  }, [open])
  return (
    <Box sx={{ display: "flex" }} component="form" onSubmit={onSubmit} >
      <Box sx={{ display: "flex", flex: 1, position: "relative" }}>
        <AsynSearchBar
          aria-describedby={id}
          onInput={openPopOver}

          ref={ref}
          fullWidth
          forceFulValue={value}

          onChange={(val) => {

            setValue(val);
          }}
          placeholder="Search Phone No."
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          InputProps={{ fullWidth: true }}
          sx={{
            textAlign: "center",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <Popper sx={{ display: "flex", flex: 1, width: anchorEl?.offsetWidth }} id={id} open={open} anchorEl={anchorEl} transition>

          {({ TransitionProps }) => (
            <Grow  {...TransitionProps} >
              <Paper sx={{ p: 1, width: "100%" }}>
                <MenuList>
                  {
                    list.map((item) => {
                      return <MenuItem tabIndex={5} key={item.phone} sx={{ textAlign: "center" }} onClick={(e) => onSelect(e, item.phone)} ><Typography align="center" width="100%" >{item.country_code}-{item.phone}</Typography></MenuItem>
                    })
                  }


                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      <Box sx={{ width: "50px", display: "flex" }}>
        <Button
          type="submit"
          disableElevation
          variant="contained"
          sx={{
            height: "100%",
            width: "100%",
            p: 0,
            minWidth: "0px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <Search />
        </Button>
      </Box>
    </Box>
  );
};
export default PhoneSearchBar;

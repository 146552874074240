import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import CustomerEnquiryReportUi from "./CustomerEnquiryReportUi";
import { getCustoemrReportApi } from "../../apis/report .api";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants/main";

const CustomerEnquiryReportController = ({ phone }) => {
  const dispatch = useDispatch();
  const getDataApi = getCustoemrReportApi;

  const [loading, setLoading] = useState(false);


  const [filters, setFilters] = useState({

  })

  const [data, setData] = useState({


  });

  const fetchDataFun = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getDataApi({ ...filters }),
        async (response) => {
          setData(response);
          setLoading(false);
        },
        (err) => {
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchDataFun();
  }, [filters]);

  return (
    <CustomerEnquiryReportUi
      filters={filters}
      setFilters={setFilters}
      data={data}
      loading={loading}
    />
  );
};
export default memo(CustomerEnquiryReportController);

import { memo, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import LeadUpdateStatusUi from "./LeadUpdateStatusUi";
import { updateLeadStatusApi } from "../../apis/lead.api";
import { findObjectKeyByValue } from "../../utils/helpers/main";
import { DEMO_LOCATION_TYPES, LEAD_STATUS } from "../../utils/constants/lead.constants";

const LeadUpdateStatusController = ({ callBack = () => { }, id, status, title, defaultData = {} }) => {
  const validate = useValidate();
  const dispatch = useDispatch();

  const modalKey = "update-lead-status";
  const dialogTitle = title ?? "Update Status To " + findObjectKeyByValue(status, LEAD_STATUS);
  const updateStatusApi = updateLeadStatusApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    status,
    remarks: "",
    audiogram: null,
    suggested_products: null,
    box_id: null,
    payment_proof: null,
    ...defaultData
  });

  const validationSchemaForCreate = useMemo(
    () => [
      {
        value: fields.audiogram,
        field: "Audiogram",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.AUDIOGRAM_RECEIVED &&
            (!fields.audiogram || fields.audiogram == "")
          ) {
            return "Audiogram is required.";
          }
          return true;
        },
      },
      {
        value: fields.suggested_products,
        field: "Product suggestion is required",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.AUDIOGRAM_APPROVED &&
            (!fields.suggested_products || fields.suggested_products == "")
          ) {
            return "Product suggestion is required";
          }
          return true;
        },
      },

      {
        value: fields.box_id,
        field: "Box Id",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.DISPATCHED_FOR_DEMO &&
            (!fields.box_id || fields.box_id == "")
          ) {
            return "Box Id  is required";
          }
          return true;
        },
      },
      {
        value: fields.payment_proof,
        field: "Payment Proof",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.DISPATCHED_FOR_DEMO &&
            (!fields.payment_proof || fields.payment_proof == "")
          ) {
            return "Payment Proof  is required";
          }
          return true;
        },
      },

      {
        value: fields.demo_date,
        field: "Demo Date",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.DEMO_SCHEDULED &&
            (!fields.demo_date || fields.demo_date == "")
          ) {
            return "Demo Date is required";
          }
          return true;
        },
      },
      {
        value: fields.demo_location_type,
        field: "Demo Location Type",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.DEMO_SCHEDULED &&
            (!fields.demo_location_type || fields.demo_location_type == "")
          ) {
            return "Select Demo Location";
          }
          if (fields.status == LEAD_STATUS.DEMO_SCHEDULED && fields.demo_location_type && fields.demo_location_type == DEMO_LOCATION_TYPES.HOME && fields.demo_location_address?.trim?.() == '') {
            return "Address is required field.";
          }
          return true;
        },
      },
      {
        value: fields.rejection_reason,
        field: "Rejection Reason",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.NOT_INTERESTED &&
            (!fields.rejection_reason || fields.rejection_reason == "")
          ) {
            return "Select reason for rejection";
          }
          return true;
        },
      },
      {
        value: fields.not_worked_products,
        field: "Tested Products",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.NOT_WORKING &&
            (!fields?.not_worked_products?.[0])
          ) {
            return "Select Tested Products";
          }
          return true;
        },
      },
      {
        value: fields.purchased_product,
        field: "Purchased Products",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.PURCHASED &&
            (!fields?.purchased_product)
          ) {
            return "Select Purchased Product";
          }
          return true;
        },
      },
      {
        value: fields.purchased_product_price,
        field: "Purchased Products",
        custom: () => {
          if (
            fields.status == LEAD_STATUS.PURCHASED &&
            (!fields?.purchased_product_price || isNaN(fields?.purchased_product_price))
          ) {
            return "Purchased Product Price Required.";
          }
          return true;
        },
      },
      {
        required: true,
        value: fields.remarks,
        field: "Remarks",
      },
    ],
    [fields]
  );

  const updateStatusFun = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      const dataToBeAdded = {};
      for (let item in { ...fields }) {
        if (typeof fields[item] == "object" && !Array.isArray(fields[item])) {
          dataToBeAdded[item] = fields[item]?._id;
        } else {
          dataToBeAdded[item] = fields[item];
        }
      }
      dispatch(
        callApiAction(
          async () => await updateStatusApi(dataToBeAdded),
          async (response) => {
            setLoading(false);
            dispatch(closeModal(modalKey));
            callBack(response);
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    updateStatusFun();
  };

  return (
    <LeadUpdateStatusUi
      title={dialogTitle}
      modalKey={modalKey}
      loading={loading}
      status={status}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(LeadUpdateStatusController);

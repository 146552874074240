import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getLeadApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getLeadByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadFetchById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadStatusApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getLeadStatusCountApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadCount,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

import { memo, useEffect, useState } from "react";
import ListUi from "./ListUi";
import moment from "moment";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { Box, ButtonBase } from "@mui/material";
import { useMemo } from "react";
import { getLeadApi } from "../../apis/lead.api";
import LeadStatusComponent from "./LeadStatusComponent";
import LeadStatusUpdateButtons from "./LeadStatusUpdateButtons";
import { openModal } from "../../store/actions/modalAction";
import LeadDetailedViewController from "./LeadDetailedViewController";
import fileDownload from "js-file-download";
import { createAddress } from "../../utils/helpers/main";

const LeadNameButton = memo(({ params, setParams }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      openModal(
        <LeadDetailedViewController id={params?._id} />,
        "md",
        undefined,
        "lead-details"
      )
    );
  };

  return (
    <ButtonBase size="small" onClick={onClick}>
      {params.first_name ?? ""} {params.last_name ?? ""}
    </ButtonBase>
  );
});
const ActionComponent = memo(({ params, setParams }) => {
  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <LeadStatusUpdateButtons
        id={params?._id}
        currentStatus={params?.status}
        isMedicalProduct={params?.is_medical_approval_needed}
        hasDestributor={params?.has_a_distributor}
        onStatusChange={(status) => {
          setParams({ ...params, status });
        }}
        defaultData={{
          address: createAddress(params),
          isMedicalProduct: params?.is_medical_approval_needed,
          hasDestributor: params?.has_a_distributor,
        }}
      />
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Lead ";
  const fetchApi = getLeadApi;
  const deleteApi = null;
  //   const updateApi = updateDealershipField;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "createdAt",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          moment(params.createdAt).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        fieldName: "name",
        label: "Customer Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => <LeadNameButton params={params} />,
      },
      {
        id: 3,
        fieldName: "products",
        label: "Product",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params, setParams) =>
          params?.products?.map((item) => item?.product_name),
      },
      {
        id: 5,
        fieldName: "phone",
        label: "Phone No.",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 6,
        fieldName: "area",
        label: "Area",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params, setParams) => params.area?.area_name,
      },
      {
        id: 4,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (
          <LeadStatusComponent onlyview params={params} setParams={setParams} />
        ),
      },
      {
        id: 7,
        fieldName: "",
        label: "Action",
        align: "right",
        minWidth: "250px",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            callback={fetchList}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: [
      "first_name",
      "middle_name",
      "last_name",
      "phone",
      "other_phone",
    ],
    sort: "last_call_at",
    sortDirection: -1,
    status: "",
    count: "",
  });

  // do not change
  // const [exportLoading, setexportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = (isExport) => {
    if (isExport) {
      // setexportLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters, csv: true }),
          (response) => {
            fileDownload(response, "leads.csv");
            // setList(response);
            setLoading(false);
            // setexportLoading(false);
          },
          (err) => {
            setLoading(false);
            // setexportLoading(false);
          },
          true
        )
      );
    } else {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters }),
          (response) => {
            setList(response);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    }
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
        fetchList={fetchList}
        // exportLoading={exportLoading}
      />
    </>
  );
};
export default ListController;

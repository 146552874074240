import { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import { StyledSearchBar } from "./SearchBar";

const AsyncSearchBar = forwardRef(
  ({ onChange, InputComponent, defaultValue, ...props }, ref) => {
    const [timeOut, setTimeOutState] = useState();
    const [val, setVal] = useState(defaultValue ?? "");
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    // const InputComp = inputComponent ?? TextField
    useImperativeHandle(ref, () => ({

      changeVal: (value) => {
        setVal(value)
      }


    }));

    useEffect(() => {
      if (firstTimeLoaded) {
        if (timeOut) {
          clearTimeout(timeOut);
        }
        const fun = () => {
          onChange(val);
          clearTimeout(timeOut);
        };

        const newTimeOut = setTimeout(fun, 400);
        setTimeOutState(newTimeOut);
      } else setFirstTimeLoaded(true);
    }, [val]);
    return (
      <StyledSearchBar

        {...props}
        value={val}
        ref={ref}
        onChange={(e) => {
          setVal(e.target.value);
        }}
      />
    );
  }
);
export default memo(AsyncSearchBar);

import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { addEnquiryApi } from "../../apis/enquiry.api";
import AddEnquiryUI from "./AddEnquiryUI";
import { validateEmail, validatePhone } from "../../utils/helpers/main";
import { ENQUIRY_TYPES_IDENTIFIER } from "../../utils/constants/lead.constants";

const AddEnquiryController = ({ callBack = () => {}, defaulData = {} }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();

  const title = "Add Enquiry ";
  const modalKey = "add-enquiry";
  const createApi = addEnquiryApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    country_code: "+91",
    phone: "",
    other_phone: "",
    address: "",
    pin: "",
    city: "",
    audiogram: "",
    state: "",
    country: "",
    channel: null,
    type: null,
    source: null,
    product: null,
    area: null,
    referral_name: "",
    referral_phone: "",
    ...defaulData,
    remarks: "",
  });

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.first_name,
        field: "First Name",
      },
      {
        value: fields.email,
        field: "Email",
        custom: () => {
          if (
            fields.email &&
            fields.email != "" &&
            !validateEmail(String(fields.email)?.trim?.())
          ) {
            return "Enter valid email.";
          }
          return true;
        },
      },
      {
        required: true,
        value: fields.country_code,
        field: "Country Code",
      },
      {
        required: true,
        value: fields.phone,
        field: "Phone ",
        custom: () => {
          if (
            fields.phone &&
            !validatePhone(fields.country_code + fields.phone)
          ) {
            return "Phone No. is invalid";
          }

          return true;
        },
      },
      {
        required: true,
        value: fields.channel,
        field: "Communication channel ",
      },

      {
        required: true,
        value: fields.type,
        field: "Enquiry Type",
      },

      {
        value: fields.source,
        field: "Enquiry Source ",
        custom: () => {
          if (
            fields.type &&
            (fields.type?.identifier == ENQUIRY_TYPES_IDENTIFIER.CSR ||
              fields.type?.identifier == ENQUIRY_TYPES_IDENTIFIER.DEALERSHIP ||
              fields.type?.identifier == ENQUIRY_TYPES_IDENTIFIER.PURCHASED ||
              fields.type?.identifier ==
                ENQUIRY_TYPES_IDENTIFIER.INFORMATION) &&
            !fields.source
          ) {
            return "Enquiry source is required.";
          }
          return true;
        },
      },
      {
        value: fields.product,
        field: "Product ",
        custom: () => {
          if (
            fields.type &&
            (fields.type?.identifier == ENQUIRY_TYPES_IDENTIFIER.PURCHASED ||
              fields.type?.identifier ==
                ENQUIRY_TYPES_IDENTIFIER.INFORMATION) &&
            !fields.product
          ) {
            return "Product is required.";
          }
          return true;
        },
      },
      {
        value: fields.area,
        field: "Area ",
        custom: () => {
          if (
            fields.type &&
            (fields.type?.identifier == ENQUIRY_TYPES_IDENTIFIER.PURCHASED ||
              fields.type?.identifier ==
                ENQUIRY_TYPES_IDENTIFIER.INFORMATION) &&
            !fields.area
          ) {
            return "Area is required.";
          }
          return true;
        },
      },
      {
        required: true,
        value: fields.remarks,
        field: "Summary of Call ",
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);

      const dataToBePassed = {};

      for (let field in fields) {
        if (typeof fields[field] == "object" && fields[field]?._id) {
          dataToBePassed[field] = fields[field]?._id;
        } else if (
          fields[field] === false ||
          fields[field] === null ||
          fields[field] === undefined ||
          fields[field] === ""
        ) {
        } else dataToBePassed[field] = fields[field];
      }
      dispatch(
        callApiAction(
          async () => await createApi(dataToBePassed),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    createFunction();
  };

  return (
    <AddEnquiryUI
      title={title}
      modalKey={modalKey}
      defaulData={defaulData}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(AddEnquiryController);

import { memo, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { Box, IconButton, Tooltip } from "@mui/material";
import MessageDilog from "../../components/MessageDilog";
import { useMemo } from "react";
// import CreateController from "./CreateController";
import { Check } from "@mui/icons-material";
import getComplainApi, { updateComplainField } from "../../apis/complain.api";
import ComplainStatusComponent from "./ComplainStatusComponent";
import { COMPLAIN_STATUS } from "../../utils/constants/main";
import fileDownload from "js-file-download";

const ActionComponent = memo(({ params, setParams, deleteApi, callback }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const doneFun = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateComplainField({ id: params._id }),
        (response) => {
          setParams({ ...params });
          callback(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDone = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={doneFun}
          title="Alert!"
          message={`Are you sure to your status is mark as complete "${
            params.first_name + " " + params.middle_name
          }" ?`}
        />,
        "sm"
        // dispatch(callSnackBar("Topic Deleted Successfully..",SNACK_BAR_VARIETNS.suceess))
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {/* <IconButton size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton> */}

      {params.status == COMPLAIN_STATUS.PENDING && (
        <Tooltip title="Mark As Completed">
          <IconButton disabled={loading} size="inherit" onClick={onDone}>
            <Check color="success" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}

      {params.status == COMPLAIN_STATUS.COMPLETE && "NA"}
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Complains ";
  const fetchApi = getComplainApi;
  const deleteApi = null;
  const updateApi = updateComplainField;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          params.first_name + " " + (params.middle_name ?? ""),
      },
      {
        id: 2,
        fieldName: "complain",
        label: "Complain",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 3,
        fieldName: "phone",
        label: "Phone",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 4,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (
          <ComplainStatusComponent
            onlyview
            params={params}
            setParams={setParams}
          />
        ),
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            callback={fetchList}
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["first_name", "middle_name"],
    sort: "",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [exportLoading, setexportLoading] = useState(false);

  const fetchList = (isExport) => {
    if (isExport) {
      setexportLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters, csv: true }),
          (response) => {
            fileDownload(response, "complain.csv");
            setexportLoading(false);
          },
          (err) => {
            setexportLoading(false);
          },
          true
        )
      );
    } else {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters }),
          (response) => {
            setList(response);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    }
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
        fetchList={fetchList}
      />
    </>
  );
};
export default ListController;

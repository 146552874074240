export const THIRD_PART_APPS_USED = {
  none: "none",
};
export const USER_ROLES = {
  ADMIN: 1,
  CRM_TEAM: 2,
  SUB_ADMIN: 3,
  AUDIOLOGIST: 4,
  DISTRIBUTOR: 5,
};
export const NOTIFICATION_TYPE = {
  general: "general",

  lead: "lead",
};
export const SNACK_BAR_VARIETNS = {
  error: "error",
  suceess: "success",
  info: "info",
  warning: "warning",
};

export const COMPLAIN_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
};
export const CSR_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
};
export const DEALERSHIP_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
};

export const REFERRAL_STATUS = {
  PENDING: 1,
  DISCOUNT_GIVEN: 2,
};

export const actions = {
  SIGN_IN: "SIGN_IN",
  SET_USER: "SET_USER",
  SIGN_OUT: "SIGN_OUT",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  CALL_SNACKBAR_DATA: "CALL_SNACKBAR_DATA",
  SET_NOTIFICATION_DATA: "SET_NOTIFICATION_DATA",
  NOTIFICATION_READ: "NOTIFICATION_READ",

  //--------------------------------------------------------------------

  SOCKET_CONNECT: "socket_connect",
  SOCKET_CONNECT_WTH_GROUPS: "socket_connect_with_groups",
  CREATE_CHAT_GROUP: "create_chat_group",
  CHANGE_ACTIVE_GROUP: "change_active_group",
  ADD_MESSAGE: "ADD_MESSAGE",
  MESSAGE_ARRIVED: "MESSAGE_ARRIVED",
  ADD_GROUP_CHAT_DATA: "ADD_GROUP_CHAT_DATA",
  MARK_AS_READ_TO_ALL: "MARK_AS_READ_TO_ALL",
  GROUP_CHAT_LOADING: "GROUP_CHAT_LOADING",
  MARK_NEW_MESSAGE_AS_READ: "MARK_NEW_MESSAGE_AS_READ",
  MARK_NEW_MESSAGE_AS_UNREAD: "MARK_NEW_MESSAGE_AS_UNREAD",
  SOCKET_DISCONNECT: "socket_disconnect",
  SOCKET_RESET: "socket_reset",

  //--------------------------------------------------------
  START_ENQUIRY_LOADING: "START_ENQUIRY_LOADING",
  SET_ENQUIRY_DATA: "SET_ENQUIRY_DATA",
  CLEAR_ENQUIRY_DATA: "CLEAR_ENQUIRY_DATA",
  ADD_ENQUIRY_DATA: "ADD_ENQUIRY_DATA",

  //--------------------------------------------------------
  START_SOURCE_LOADING: "START_SOURCE_LOADING",
  SET_SOURCE_DATA: "SET_SOURCE_DATA",
  CLEAR_SOURCE_DATA: "CLEAR_SOURCE_DATA",
  ADD_SOURCE_DATA: "ADD_SOURCE_DATA",

  //--------------------------------------------------------
  START_COMMUNICATION_CHANNEL_LOADING: "START_COMMUNICATION_CHANNEL_LOADING",
  SET_COMMUNICATION_CHANNEL_DATA: "SET_COMMUNICATION_CHANNEL_DATA",
  CLEAR_COMMUNICATION_CHANNEL_DATA: "CLEAR_COMMUNICATION_CHANNEL_DATA",
  ADD_COMMUNICATION_CHANNEL_DATA: "ADD_COMMUNICATION_CHANNEL_DATA",

  //--------------------------------------------------------
  START_PRODUCT_LOADING: "START_PRODUCT_LOADING",
  SET_PRODUCT_DATA: "SET_PRODUCT_DATA",
  CLEAR_PRODUCT_DATA: "CLEAR_PRODUCT_DATA",
  ADD_PRODUCT_DATA: "ADD_PRODUCT_DATA",

  //--------------------------------------------------------
  START_DISTRIBUTION_AREA_LOADING: "START_DISTRIBUTION_AREA_LOADING",
  SET_DISTRIBUTION_AREA_DATA: "SET_DISTRIBUTION_AREA_DATA",
  CLEAR_DISTRIBUTION_AREA_DATA: "CLEAR_DISTRIBUTION_AREA_DATA",
  ADD_DISTRIBUTION_AREA_DATA: "ADD_DISTRIBUTION_AREA_DATA",
};

export const DATE_TIME_FILTERS = {
  this_week: "This Week",
  this_month: "This Month",
  this_year: "This Year",
  last_month: "Last Month",
  last_year: "Last Year",
  last_week: "Last Week",
  today: "Today",
  yesterday: "Yesterday",
};

export const FILE_TYPES = {
  IMAGES: 1,
  PDFS: 2,
};

import { memo } from "react";
import { findObjectKeyByValue } from "../../utils/helpers/main";
import { Chip } from "@mui/material";
import { AudiogramStatusColor } from "../../utils/helpers/lead.helper";
import { LEAD_STATUS } from "../../utils/constants/lead.constants";

const AudiogramStatusComponent = memo(({ params, setParams }) => {
  return (
    <Chip
      size="small"
      color={AudiogramStatusColor(params.status)}
      label={findObjectKeyByValue(params.status, LEAD_STATUS)}
    />
  );
});
export default AudiogramStatusComponent;

import { CircularProgress, Grid, MenuItem } from "@mui/material";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { Fragment, memo } from "react";
import { CATEGORY } from "../../utils/constants/lead.constants";
import { getTemplateApi } from "../../apis/template.api";
import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  system_default,
  loading,
  onSubmit,
}) => {
  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {!system_default && (
              <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields.name}
                onChange={(e) =>
                  setFields({ ...fields, err: "", name: e.target.value })
                }
                type="text"
                label={"Enquiry Name*"}
              />
            )}

            {!system_default && (
              <CustomInput
                disabled={loading}
                value={fields.identifier}
                onChange={(e) =>
                  setFields({ ...fields, err: "", identifier: e.target.value })
                }
                type="text"
                label={"Identifier"}
              />
            )}

            {
              <CustomInput
                disabled={loading}
                value={fields.priority}
                onChange={(e) =>
                  setFields({ ...fields, err: "", priority: e.target.value })
                }
                type="number"
                label={"Priority*"}
              />
            }
            {
              <Grid item xs={6}>
                <AsyncDropDown
                  label="Select Template*"
                  lazyFun={async (params) => {
                    return await getTemplateApi({ ...params });
                  }}
                  defaultVal={fields.template_for_customer}
                  value={fields.template_for_customer}
                  OptionComponent={({ option, ...rest }) => {
                    return (
                      <>
                        <MenuItem
                          {...rest}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {option.title}{" "}
                          {option.category == CATEGORY.EMAIL
                            ? "(Email)"
                            : option.category == CATEGORY.MESSAGE
                            ? "(Message)"
                            : "(Whats Up)"}
                        </MenuItem>
                      </>
                    );
                  }}
                  onChange={async (changedVal) => {
                    setFields({
                      ...fields,
                      template_for_customer: changedVal?.map(
                        (item) => item?._id
                      ),
                    });
                  }}
                  titleKey={"title"}
                  valueKey={"_id"}
                  multiple={true}
                />
              </Grid>
            }
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);

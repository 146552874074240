import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getCallPoolListApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.callpoolBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteCallPoolApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.callpoolDelete,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

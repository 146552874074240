import { Button, ButtonGroup, Paper, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import SubmitButton from "../../components/button/SubmitButton";
import DataTable from "../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsyncSearchBar from "../../components/inputs/AsynSearchBar";
import UserCount from "./UserCount";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  loading,
  onCreateBtnClick,
  columns,
}) => {
  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Box mb={2}>
                <SubmitButton
                  variant="contained"
                  onClick={onCreateBtnClick}
                  title={"Add Member"}
                />
              </Box>
            </FilterTitleBox>

            <UserCount setFilters={setFilters} filters={filters} />

            <FiltersBox mt={3}>
              <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}>
                <AsyncSearchBar
                  fullWidth
                  title="Search Name | Email"
                  size="small"
                  placeholder={"Search Name | Email"}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                />
              </PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1 }}
                pl={3}
              ></PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
                pl={3}
              >
                <ButtonGroup disableElevation variant="outlined">
                  <Button
                    onClick={() => setFilters({ ...filters, deleted: null })}
                    variant={filters.deleted == null ? "contained" : "outlined"}
                  >
                    Active User
                  </Button>
                  <Button
                    onClick={() => setFilters({ ...filters, deleted: true })}
                    variant={filters.deleted == true ? "contained" : "outlined"}
                  >
                    Deleted User
                  </Button>
                </ButtonGroup>
              </PaddingBoxInDesktop>
            </FiltersBox>
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListUi;

import { useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import {
  deleteUserApi,
  getUserApi,
  undoUserDeleteApi,
} from "../../apis/user.api";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { findNameByRole } from "../../utils/helpers/main";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
import {
  ChangeCircle,
  Delete,
  Edit,
  LockOpen,
  Undo,
} from "@mui/icons-material";
import MessageDilog from "../../components/MessageDilog";
import ResetPasswordController from "./ResetPassword.controller";
import { USER_ROLES } from "../../utils/constants/main";
import ModulePermissionController from "./ModulePermissionController";

const ActionComponent = ({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  const onResetPassword = () => {
    dispatch(
      openModal(
        <ResetPasswordController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  const deleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const undoDeleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await undoUserDeleteApi({ id: params._id }),
        (response) => {
          setParams({ ...params, deleted: false });
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };
  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure to delete "${params.name || params.title}" ?`}
        />,
        "sm"
      )
    );
  };
  const onEdits = () => {
    dispatch(
      openModal(
        <ModulePermissionController
          id={params._id}
          {...params}
          callBack={(updatedData) => {
            console.log(updatedData)
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm",
        false,
        "modules"
      )
    );
  };

  const onUndo = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={undoDeleteFun}
          title="Alert!"
          message={`Are you sure to undo delete for "${
            params.name || params.title
          }" ?`}
        />,
        "sm"
      )
    );
  };
  if (params.deleted)
    return (
      <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {" "}
        <IconButton onClick={onUndo}>
          <Undo color="error" />
        </IconButton>
      </Box>
    );
  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <Tooltip title="Module Access">
        <IconButton disabled={loading} size="inherit" onClick={onEdits}>
          <ChangeCircle color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <IconButton disabled={loading} size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>
      <IconButton disabled={loading} size="inherit" onClick={onResetPassword}>
        <LockOpen color="info" fontSize="inherit" />
      </IconButton>
      {user.data.role == USER_ROLES.ADMIN && (
        <>
          {loading && <CircularProgress color="primary" fontSize="inherit" />}
          {!loading && (
            <IconButton disabled={loading} size="inherit" onClick={onDelete}>
              <Delete color="error" fontSize="inherit" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Team Members";
  const fetchApi = getUserApi;
  const deleteApi = deleteUserApi;
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    role: "",
    sort: "",
    sortDirection: 1,
    deleted: null,
  });

  const columns = useMemo(() => {
    const arr = [
      { id: 1, fieldName: "name", label: "Name", align: "left", sort: true },
      { id: 2, fieldName: "email", label: "Email", align: "left" },
      { id: 6, fieldName: "phone", label: "Phone", align: "left" },
      {
        id: 4,
        fieldName: "role",
        label: "Role",
        align: "left",
        renderValue: (params) => findNameByRole(params.role) || "",
      },
    ];

    if (filters.deleted) {
      arr.push({
        id: 6,
        fieldName: "deletedaction",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            key={Math.random()}
            deleted={true}
            deleteApi={deleteApi}
            params={{ ...params, deleted: true }}
            setParams={setParams}
      
          />
        ),
      });
    } else {
      arr.push({
        id: 5,
        fieldName: "action",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            key={Math.random()}
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      });
    }

    return arr;
  }, [dispatch, filters.deleted]);

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;

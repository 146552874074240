import { CircularProgress, Box, Autocomplete } from "@mui/material";
import { memo, useState } from "react";
import { titleCase } from "../../utils/helpers/main";
import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { findObjectKeyByValue } from "../../utils/helpers/main";
import { CATEGORY } from "../../utils/constants/lead.constants";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const [editor, setEditor] = useState(null);
  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {
              <>
                <CustomInput
                  autoFocus={true}
                  disabled={loading}
                  value={fields.title}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      title: e.target.value,
                    })
                  }
                  type="text"
                  label={"Title*"}
                />

                <CustomInput
                  disabled={loading}
                  value={fields.identifier}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      identifier: e.target.value,
                    })
                  }
                  type="text"
                  label={"Identifier"}
                />

                <Autocomplete
                  value={findObjectKeyByValue(fields.category, CATEGORY)}
                  onChange={(e, newVal) => {
                    setFields({
                      ...fields,
                      category: newVal ? newVal._id : null,
                    });
                  }}
                  options={[
                    ...Object.keys(CATEGORY).map((key) => ({
                      label: titleCase(key),
                      _id: CATEGORY[key],
                    })),
                  ]}
                  sx={{
                    width: "100%",
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center" },
                  }}
                  renderInput={(params) => (
                    <CustomInput
                      placeholder="Select Category Option*"
                      {...params}
                      label="Select Category Option*"
                    />
                  )}
                />

                <Box mt={2}>
                  <CKEditor
                    config={{
                      placeholder: "Enter Template...",
                      style: { height: "500px", minHeight: "500px" },
                      toolbar: {
                        items: [
                          "p",
                          "heading",
                          "italic",
                          "bold",
                          "blockquote",
                          "link",
                          "table",
                          "undo",
                          "redo",
                          "numberedList",
                          "bulletedList",
                        ],
                      },
                    }}
                    editor={ClassicEditor}
                    data={fields.template}
                    onReady={(editor) => {
                      setEditor(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFields({ ...fields, template: data });
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </Box>
              </>
            }
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);

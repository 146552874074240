import { Autocomplete, CircularProgress, MenuItem } from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";
import CustomInput from "../../components/inputs/CustomInput";
import { USER_ROLES } from "../../utils/constants/main";
import { findNameByRole, titleCase } from "../../utils/helpers/main";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { getAreaApi } from "../../apis/area.api";

const CreateUi = ({
  title,
  isInstaller,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const { user } = useSelector((state) => state);

  let allowedRoles = [
    ...Object.keys(USER_ROLES).map((key) => ({
      label: titleCase(key),
      _id: USER_ROLES[key],
    })),
  ];

  if (user.data.role === USER_ROLES.partner_admin) {
    allowedRoles = [];
    Object.keys(USER_ROLES).forEach((key) => {
      if (USER_ROLES[key] != USER_ROLES.ADMIN)
        allowedRoles.push({ label: titleCase(key), _id: USER_ROLES[key] });
    });
  }

  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <CustomInput
              autoFocus={true}
              disabled={loading}
              value={fields.name}
              onChange={(e) =>
                setFields({ ...fields, err: "", name: e.target.value })
              }
              type="text"
              label={"Name*"}
            />

            {!isUpdate && (
              <CustomInput
                disabled={loading}
                value={fields.email}
                onChange={(e) =>
                  setFields({ ...fields, err: "", email: e.target.value })
                }
                type="text"
                label={"Email*"}
              />
            )}
            {
              <CustomInput
                disabled={loading}
                value={fields.phone}
                onChange={(e) =>
                  setFields({ ...fields, err: "", phone: e.target.value })
                }
                type="tel"
                label={"Phone No*"}
              />
            }
            {!isUpdate && (
              <CustomInput
                disabled={loading}
                value={fields.password}
                onChange={(e) =>
                  setFields({ ...fields, err: "", password: e.target.value })
                }
                type="password"
                label={"Password*"}
              />
            )}

            {!isInstaller && user.data.role != USER_ROLES.DISTRIBUTOR && (
              <Autocomplete
                disableClearable
                options={allowedRoles}
                value={findNameByRole(fields.role)}
                onChange={(e, newVal) => {
                  setFields({ ...fields, role: newVal ? newVal._id : null });
                }}
                sx={{
                  width: "100%",
                  display: "flex",
                  "*": { display: "flex", justifyContent: "center" },
                }}
                renderInput={(params) => (
                  <CustomInput
                    placeholder="Select Role*"
                    {...params}
                    label="Select Role*"
                  />
                )}
              />
            )}
            {!isInstaller && user.data.role == USER_ROLES.DISTRIBUTOR && (
              <Autocomplete
                disableClearable
                options={allowedRoles}
                readOnly
                value={findNameByRole(fields.role)}
                onChange={(e, newVal) => {
                  setFields({ ...fields, role: newVal ? newVal._id : null });
                }}
                sx={{
                  width: "100%",
                  display: "flex",
                  "*": { display: "flex", justifyContent: "center" },
                }}
                renderInput={(params) => (
                  <CustomInput
                    placeholder="Select Role*"
                    {...params}
                    label="Select Role*"
                  />
                )}
              />
            )}

            {user.data.role != USER_ROLES.DISTRIBUTOR &&
              [USER_ROLES.DISTRIBUTOR, USER_ROLES.sales].includes(
                fields.role
              ) && (
                <AsyncDropDown
                  value={fields.area}
                  defaultVal={fields.area}
                  lazyFun={getAreaApi}
                  label="Select Area*"
                  OptionComponent={({ option, ...rest }) => {
                    return <MenuItem {...rest}>{option.area_name}</MenuItem>;
                  }}
                  onChange={async (changedVal) => {
                    setFields({
                      ...fields,
                      area: changedVal ? changedVal._id : null,
                    });
                  }}
                  titleKey={"area_name"}
                  valueKey={"_id"}
                />
              )}
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);

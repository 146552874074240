import { memo, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { openModal } from "../../store/actions/modalAction";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
import { Edit, Info } from "@mui/icons-material";
import { getTemplateApi } from "../../apis/template.api";
import { CATEGORY } from "../../utils/constants/lead.constants";
import DetailsUI from "./DetailsUI";

const ActionComponent = memo(({ params, setParams }) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  const onDetail = () => {
    dispatch(
      openModal(<DetailsUI params={params} />, "sm", undefined, "template-list")
    );
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex" }}>
        <Box>
          <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
          </IconButton>
        </Box>
        <Box>
          <Tooltip title=" view template" arrow>
            <IconButton size="inherit" onClick={onDetail}>
              <Info color="info" fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Template ";
  const fetchApi = getTemplateApi;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "title",
        label: "Title",
        align: "left",
        sort: true,
      },
      {
        id: 2,
        fieldName: "identifier",
        label: "Identifier",
        align: "left",
      },
      {
        id: 4,
        fieldName: "category",
        label: "Category",
        align: "left",
        renderValue: (params) =>
          params.category == CATEGORY.EMAIL
            ? (params.category = "Email")
            : params.category == CATEGORY.WHATSUP
            ? (params.category = "What's Up")
            : params.category == CATEGORY.MESSAGE
            ? (params.category = "Message")
            : "NA",
      },

      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <>
            <ActionComponent params={params} setParams={setParams} />
          </>
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["title"],
    sort: "",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;

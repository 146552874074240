import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getAllSourcesApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.sourcesAll,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getSourceApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.sourcesBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getSourceByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.sourcesById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addSourceApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.sourcesBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateSourceField = async (data) => {
  const callResponse = await axios({
    url: endpoints.sourcesBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export default getSourceApi;

import {
  AddIcCall,
  Assessment,
  AudioFile,
  Call,
  DomainAdd,
  Groups2Outlined,
  Key,
  Leaderboard,
  LocationOn,
  NextWeek,
  PsychologyAlt,
  RecentActors,
  Settings,
  SupportAgent,
  ThreeP,
  VolunteerActivism,
  WorkHistory,
} from "@mui/icons-material";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import HubIcon from "@mui/icons-material/Hub";
import BadgeIcon from "@mui/icons-material/Badge";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { Navigate } from "react-router-dom";
import AppContainer from "./components/layouts/common/AppContainer";

import SignInController from "./pages/signin/SignInController";
import UserListController from "./pages/user/ListController";

import SourcesListController from "./pages/sources/ListController";

import { USER_ROLES } from "./utils/constants/main";
import PagenotFound from "./components/layouts/PagenotFound";
import ListController from "./pages/secrets/ListController";

import AreaController from "./pages/area/AreaController";

import ProductListController from "./pages/product/ListController";
import ChannelListController from "./pages/channel/ListController";
import EnquiryTypeListController from "./pages/enquiry-type/ListController";
import ComplainListController from "./pages/complain/ListController";
import CSRListController from "./pages/CSR/ListController";
import DealershipListController from "./pages/dealership/ListController";
import TemplateListController from "./pages/template/ListController";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchCustomerMain from "./pages/customer/SearchCustomerMain";
import LeadListController from "./pages/lead/ListController";
import CallpoolListController from "./pages/callpool/ListController";
import AudioGramListController from "./pages/audiogram/ListController";
import ReferralListController from "./pages/Referral/ListController";
import ReasonListController from "./pages/Reason/ListController";
import DispatchedListController from "./pages/dispatched/ListController";
import { MODULES } from "./utils/constants/module.constant";
import CustomerEnquiryReportController from "./pages/reports/CustomerEnquiryReportController";
const defaultRedirect = (user) => {
  if (!user.isLoggedIn) {
    return "/sign-in";
  }
  if (user.data.modules?.includes(MODULES.CALL_MODULE)) {
    return "/customer";
  }
  if (user.data.modules?.includes(MODULES.LEAD_MODULE)) {
    return "/leads";
  }
  if (user.data.modules?.includes(MODULES.AUDIOLOGY_APPROVAL_MODULE)) {
    return "/audiogram-approval";
  }
  if (user.data.modules?.includes(MODULES.COMPLAIN_MODULE)) {
    return "/customer-enquiries/complain";
  }
  if (user.data.modules?.includes(MODULES.CSR_MODULE)) {
    return "/customer-enquiries/csr";
  }
  if (user.data.modules?.includes(MODULES.DEALERSHIP_MODULE)) {
    return "/customer-enquiries/dealership";
  }
  return "/sign-in";
};
const moduleAccessCondition = (user, modules = [], roles = []) => {
  if (!user?.isLoggedIn) return false;

  let moduleAcces = false;
  let roleAccess = false;
  const userAllowedModules = user.data?.modules ?? [];
  const userRole = user.data?.role;

  for (let userAllowedModule of userAllowedModules) {
    for (let accessModule of modules) {
      if (userAllowedModule == accessModule) {
        moduleAcces = true;
        break;
      }
    }
  }
  roleAccess = roles?.includes(userRole);
  return moduleAcces || roleAccess;
};
const defineRoutes = (user) => {
  const redirectDefault = defaultRedirect(user);
  return [
    {
      path: "sign-in",
      element: !user.isLoggedIn ? (
        <SignInController />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
      hideInPannel: true,
    },
    {
      path: "",
      icon: <SupportAgent />,
      title: "Dashboard",
      element: <Navigate replace to={redirectDefault} />,
      hideInPannel: true,
    },
    {
      path: "report",
      icon: <Assessment />,
      title: "Report",

      hideInPannel: !moduleAccessCondition(user, [], [USER_ROLES.ADMIN]),
      element: moduleAccessCondition(user, [], [USER_ROLES.ADMIN]) ? (
        <AppContainer>
          <CustomerEnquiryReportController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "customer",
      icon: <SupportAgent />,
      title: "Inbound Leads",
      hideInPannel: !moduleAccessCondition(user, [MODULES.CALL_MODULE]),
      element: moduleAccessCondition(user, [MODULES.CALL_MODULE]) ? (
        <AppContainer hideOverflow>
          <SearchCustomerMain />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "leads",
      icon: <AddIcCall />,
      title: "Outbound Leads",

      hideInPannel: !moduleAccessCondition(user, [MODULES.LEAD_MODULE]),
      element: moduleAccessCondition(user, [MODULES.LEAD_MODULE]) ? (
        <AppContainer>
          <LeadListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "callpool",
      icon: <Call />,
      title: "Pending Call Pool",

      hideInPannel: !moduleAccessCondition(user, [MODULES.CALLPOOL_MODULE]),
      element: moduleAccessCondition(user, [MODULES.CALLPOOL_MODULE]) ? (
        <AppContainer>
          <CallpoolListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "audiogram-approval",
      icon: <AudioFile />,
      title: "Audiogram Approval",

      hideInPannel: !moduleAccessCondition(
        user,
        [MODULES.AUDIOLOGY_APPROVAL_MODULE],
        [USER_ROLES.AUDIOLOGIST]
      ),
      element: moduleAccessCondition(
        user,
        [MODULES.AUDIOLOGY_APPROVAL_MODULE],
        [USER_ROLES.AUDIOLOGIST]
      ) ? (
        <AppContainer>
          <AudioGramListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "dispatch-orders",
      icon: <WorkHistory />,
      title: "Dispatched Trial Orders",

      hideInPannel: !moduleAccessCondition(user, [MODULES.LEAD_MODULE], []),
      element: moduleAccessCondition(user, [MODULES.LEAD_MODULE], []) ? (
        <AppContainer>
          <DispatchedListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "settings",
      icon: <Settings />,
      title: "Settings",
      hideInPannel: !moduleAccessCondition(
        user,
        [
          MODULES.SECRETS_MANAGEMENT_MODULE,
          MODULES.PRODUCT_MODULE,
          MODULES.LEAD_SOURCE_MODULE,
          MODULES.AREA_MODULE,
          MODULES.COMMUNICATION_CHANNEL_MODULE,
          MODULES.LEAD_TYPE_MODULE,
          MODULES.TEMPLATE_MODULE,
        ],
        [USER_ROLES.ADMIN]
      ),
      element: moduleAccessCondition(
        user,
        [
          MODULES.SECRETS_MANAGEMENT_MODULE,
          MODULES.PRODUCT_MODULE,
          MODULES.LEAD_SOURCE_MODULE,
          MODULES.AREA_MODULE,
          MODULES.COMMUNICATION_CHANNEL_MODULE,
          MODULES.LEAD_TYPE_MODULE,
          MODULES.TEMPLATE_MODULE,
        ],
        [USER_ROLES.ADMIN]
      ) ? (
        <AppContainer />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),

      children: [
        {
          path: "secrets",
          title: "Secrets Management",
          icon: Key,

          hideInPannel: !moduleAccessCondition(user, [
            MODULES.SECRETS_MANAGEMENT_MODULE,
          ]),
          element: moduleAccessCondition(user, [
            MODULES.SECRETS_MANAGEMENT_MODULE,
          ]) ? (
            <ListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "source",
          title: "Lead Sources",
          icon: NextWeek,

          hideInPannel: !moduleAccessCondition(user, [
            MODULES.LEAD_SOURCE_MODULE,
          ]),
          element: moduleAccessCondition(user, [MODULES.LEAD_SOURCE_MODULE]) ? (
            <SourcesListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "area",
          title: "Area",
          icon: LocationOn,

          hideInPannel: !moduleAccessCondition(user, [MODULES.AREA_MODULE]),
          element: moduleAccessCondition(user, [MODULES.AREA_MODULE]) ? (
            <AreaController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "product",
          title: "Product",
          icon: ProductionQuantityLimitsIcon,

          hideInPannel: !moduleAccessCondition(user, [MODULES.PRODUCT_MODULE]),
          element: moduleAccessCondition(user, [MODULES.PRODUCT_MODULE]) ? (
            <ProductListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "channel",
          title: "Communication Channel",
          icon: HubIcon,

          hideInPannel: !moduleAccessCondition(user, [
            MODULES.COMMUNICATION_CHANNEL_MODULE,
          ]),
          element: moduleAccessCondition(user, [
            MODULES.COMMUNICATION_CHANNEL_MODULE,
          ]) ? (
            <ChannelListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "enquiry-type",
          title: "Enquiry Type",
          icon: BadgeIcon,
          hideInPannel: !moduleAccessCondition(user, [
            MODULES.LEAD_TYPE_MODULE,
          ]),
          element: moduleAccessCondition(user, [MODULES.LEAD_TYPE_MODULE]) ? (
            <EnquiryTypeListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },

        {
          path: "template",
          title: "Template",
          icon: DescriptionIcon,
          // hideInPannel: user.data.role == USER_ROLES.partner_admin,
          // element:
          //   user.data.role == USER_ROLES.partner_admin ? (
          //     <></>
          //   ) : (
          //     <TemplateListController />
          //   ),
          hideInPannel: !moduleAccessCondition(user, [MODULES.TEMPLATE_MODULE]),
          element: moduleAccessCondition(user, [MODULES.TEMPLATE_MODULE]) ? (
            <TemplateListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "referral",
          icon: ThreeP,
          title: "Referrals",

          hideInPannel: !moduleAccessCondition(user, [], [USER_ROLES.ADMIN]),
          element: moduleAccessCondition(user, [], [USER_ROLES.ADMIN]) ? (
            <ReferralListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "reason",
          icon: PsychologyAlt,
          title: "Rejection Reason",

          hideInPannel: !moduleAccessCondition(user, [], [USER_ROLES.ADMIN]),
          element: moduleAccessCondition(user, [], [USER_ROLES.ADMIN]) ? (
            <ReasonListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
      ],
    },
    {
      path: "users",
      icon: <Groups2Outlined />,
      title: "Users",
      hideInPannel: !moduleAccessCondition(user, [MODULES.USER_MODULE]),
      element: moduleAccessCondition(user, [MODULES.USER_MODULE]) ? (
        <AppContainer>
          <UserListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "customer-enquiries",
      icon: <RecentActors />,
      title: "Enquiries",
      hideInPannel: !moduleAccessCondition(
        user,
        [
          MODULES.COMPLAIN_MODULE,
          MODULES.CSR_MODULE,
          MODULES.DEALERSHIP_MODULE,
        ],
        [USER_ROLES.ADMIN]
      ),
      element: moduleAccessCondition(
        user,
        [
          MODULES.COMPLAIN_MODULE,
          MODULES.CSR_MODULE,
          MODULES.DEALERSHIP_MODULE,
        ],
        [USER_ROLES.ADMIN]
      ) ? (
        <AppContainer />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
      children: [
        {
          path: "complain",
          title: "Complains",
          icon: NewReleasesIcon,

          // element: <ComplainListController />,
          hideInPannel: !moduleAccessCondition(user, [MODULES.COMPLAIN_MODULE]),
          element: moduleAccessCondition(user, [MODULES.COMPLAIN_MODULE]) ? (
            <ComplainListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "csr",
          title: "CSR",
          icon: VolunteerActivism,

          hideInPannel: !moduleAccessCondition(user, [MODULES.CSR_MODULE]),
          element: moduleAccessCondition(user, [MODULES.CSR_MODULE]) ? (
            <CSRListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "dealership",
          title: "Dealership",
          icon: DomainAdd,
          hideInPannel: !moduleAccessCondition(user, [
            MODULES.DEALERSHIP_MODULE,
          ]),
          element: moduleAccessCondition(user, [MODULES.DEALERSHIP_MODULE]) ? (
            <DealershipListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
      ],
    },

    {
      path: "*",
      element: !user.isLoggedIn ? (
        <Navigate replace to={"/sign-in"} />
      ) : (
        <AppContainer>
          <PagenotFound />
        </AppContainer>
      ),
      hideInPannel: true,
    },
  ];
};
export default defineRoutes;

/* 

================================================================
Demo Object
================================================================
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to="/leaves" />,
            hideInPannel:true, //for showing it onSide pannel or not
            title: "Leave Management", // it will use as name in side navbar
            component:<></>


             children: [
                {
                    path: "",
                    title: "My Leaves",// it will use as name in side navbar
                    element: <MyLeaveController />,
                    hideInPannel:true,
                }
            ],
        },




================================================================
================================================================


*/

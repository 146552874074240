import { actions } from "../../utils/constants/main";

const initialState = {
  loading: true,
  data: [],
};
const enquiryTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ENQUIRY_DATA:
      return { ...state, loading: false, data: action.data };
    case actions.START_ENQUIRY_LOADING:
      return { ...state, loading: true };
    case actions.ADD_ENQUIRY_DATA:
      const newDoc = action.data;
      const newData = [...state.data];
      newData.push(newDoc);
      newData.sort((a, b) => a.priority - b.priority);
      return { ...state, loading: false, data: newData };
    case actions.CLEAR_ENQUIRY_DATA:
      return { ...state, loading: false, data: [] };
    default:
      return { ...state };
  }
};
export default enquiryTypeReducer;

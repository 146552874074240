import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getTemplateApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.templateFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getTemplateByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.templateFetchByid,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addTemplateApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.templateBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateTemplateApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.templateUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

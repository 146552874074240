import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { getLeadByIdApi } from "../../apis/lead.api";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import LeadDetailedView from "./LeadDetailedViewUi";

const LeadDetailViewController = ({ id }) => {
  const dispatch = useDispatch();
  const getByIdApi = getLeadByIdApi;
  const modalKey = "lead-details";
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
  });

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response, id });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err, id });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchById(id);
  }, [id]);

  return (
    <CustomDialog
      id={modalKey}
      dialogProps={{
        sx: { overflow: "hidden", height: "1000px", display: "flex" },
      }}
      title="Lead Details"
    >
      <LeadDetailedView data={fields} loading={loading} />
    </CustomDialog>
  );
};
export default memo(LeadDetailViewController);

import { CircularProgress } from "@mui/material";
import { memo } from "react";
import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {
              <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields.name}
                onChange={(e) =>
                  setFields({ ...fields, err: "", name: e.target.value })
                }
                type="text"
                label={"Source Name*"}
              />
            }

            {
              <CustomInput
                disabled={loading}
                value={fields.identifier}
                onChange={(e) =>
                  setFields({ ...fields, err: "", identifier: e.target.value })
                }
                type="text"
                label={"Identifier(developer purpose)*"}
              />
            }

            {
              <CustomInput
                disabled={loading}
                value={fields.priority}
                onChange={(e) =>
                  setFields({ ...fields, err: "", priority: e.target.value })
                }
                type="number"
                label={"Priority*"}
              />
            }
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);

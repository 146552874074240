import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableCell,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import { useSelector } from "react-redux";
import DynamicDataDropDown from "../../components/inputs/DynamicDataDropDown";
import { StyledSearchBar } from "../../components/inputs/SearchBar";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const TableRowComponent = styled(TableRow)(({ theme, bg, even }) => ({

  overflow: "hidden",

  background: bg ? theme.palette.primary.main : (even ? theme.palette.light.main : theme.palette.grey[100]),
  "*": {

    color: bg ? theme.palette.light.main : theme.palette.text.primary
  }
}));


const CustomerEnquiryReportUi = ({ data, loading, filters, setFilters }) => {

  const {
    communicationChannel,
    product,
    distributionArea,
    source,
    enquiryType,
  } = useSelector((state) => state);


  return (
    <>
      {/* <Box component={Paper} sx={{ display: "flex", flex: 1 }} p={3}>
        <Box sx={{ height: "100%", width: "100%" }}> */}

      <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
        <Box mb={4}>
          <FilterTitleBox mb={2}>
            <Typography variant="h5" mb={2}>
              Enquiry Report
            </Typography>

            <Box mb={2} sx={{ display: "flex" }}>
              <Box mr={2} />
            </Box>
          </FilterTitleBox>

          <FiltersBox mt={3} mb={3}>
            <PaddingBoxInDesktop flex={1}>
              <TimeRangeSelector
                onChange={(val) => setFilters({ ...filters, ...val })}
              />
            </PaddingBoxInDesktop>
            <PaddingBoxInDesktop flex={1}  ml={2}>
              <DynamicDataDropDown
                fullWidth
                onChange={(e, val) =>
                  setFilters({ ...filters, channel: val?._id })
                }
                reducerDataObj={communicationChannel}
                titleKey="channel_name"
                valueKey="_id"
                disableClearable={false}
                renderInput={(params) => <StyledSearchBar placeholder={"Communication Channel"} {...params} size="small" />}
              />
            </PaddingBoxInDesktop>
            <PaddingBoxInDesktop flex={1} ml={2}>
              <DynamicDataDropDown
                fullWidth
                disableClearable={false}
                onChange={(e, val) => setFilters({ ...filters, source: val?._id })                  
                }
                reducerDataObj={source}
                titleKey="name"
                valueKey="_id"
                renderInput={(params) => <StyledSearchBar placeholder={"Source"} {...params} size="small" />}
              />
            </PaddingBoxInDesktop>
          </FiltersBox>
          <FiltersBox mt={3} mb={3}>
          
            <PaddingBoxInDesktop flex={1}>
              <DynamicDataDropDown
                fullWidth
                onChange={(e, val) =>
                  setFilters({ ...filters, product: val?._id })
                }
                reducerDataObj={product}
                titleKey="product_name"
                valueKey="_id"
                disableClearable={false}
                renderInput={(params) => <StyledSearchBar placeholder={"Product"} {...params} size="small" />}
              />
            </PaddingBoxInDesktop>
            <PaddingBoxInDesktop flex={1} ml={2}>
              <DynamicDataDropDown
                fullWidth
                disableClearable={false}
                onChange={(e, val) => setFilters({ ...filters, area: val?._id })                  
                }
                reducerDataObj={distributionArea}
                titleKey="area_name"
                valueKey="_id"
                renderInput={(params) => <StyledSearchBar placeholder={"Distribution Area"} {...params} size="small" />}
              />
            </PaddingBoxInDesktop>
          </FiltersBox>
          {loading ? <CenteredBox>
            <Skeleton variant="rectangular" ></Skeleton>
            <Skeleton variant="rectangular" ></Skeleton>
            <Skeleton variant="rectangular" ></Skeleton>

          </CenteredBox> : <Table size="small" >
            {
              data && Array.isArray(data) && data.map((userRow, index) => {
                return <TableRowComponent key={index} bg={index == 0} even={index % 2}>
                  {userRow.map((value, typeIndex) => <TableCell key={typeIndex}>
                    {
                      typeof value == "object" ? value?.name : value
                    }
                  </TableCell>)}
                </TableRowComponent>
              })
            }
          </Table>}
        </Box>
      </Paper>

    </>
  );


};
export default CustomerEnquiryReportUi;

import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getAllcommunicationChannelApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.channelAll,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getChannelApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.channelFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getChannelByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.channelFetchByid,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addChannelApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.channelBase,
    method: "POST",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateChannelField = async (data) => {
  const callResponse = await axios({
    url: endpoints.channelUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export default getChannelApi;

export const CATEGORY = {
  EMAIL: 1,
  WHATSUP: 2,
  MESSAGE: 3,
};

export const LEAD_STATUS = {
  INFORMATION: 1,
  WILLING_TO_PURCHASE: 2,
  AUDIOGRAM_ASKED: 3,
  AUDIOGRAM_RECEIVED: 4,
  AUDIOGRAM_APPROVED: 5,
  AUDIOGRAM_REJECTED: 6,
  DEMO_SCHEDULED: 7,
  NOT_INTERESTED: 8,
  NOT_WORKING: 9,
  DISPATCHED_FOR_DEMO: 10,
  PURCHASED: 11,
  RETURNED: 12,
};
export const CALLPOOL_STATUS = {
  PENDING:1,
  CALLED:2

}

export const LEAD_ANALYTICS_STATUS = {
  PENDING: 1,
  WIP: 2,
  CLOSED: 3,
};

export const AUDIOGRAM_PARENT_STATUS = {
  PENDING_APPROVAL: 1,
  ACTIONED: 2,
};
export const CLOSING_STATUS_OF_LEAD = [
  LEAD_STATUS.PURCHASED,
  LEAD_STATUS.NOT_WORKING,
  LEAD_STATUS.NOT_INTERESTED,
];

export const LEAD_LOG_TYPE = {
  LEAD_CREATED: 1,
  LEAD_UPDATED: 2,
  LEAD_TRANSFERRED: 3,
  LEAD_RECALLED_BEFORE_CLOSE: 4,
  STATUS_CHANGED: 5,
};
export const ENQUIRY_TYPES_IDENTIFIER = {
  COMPLAIN: "COMPLAIN",
  CSR: "CSR",
  DEALERSHIP: "DEALERSHIP",
  INFORMATION: "INFORMATION",
  PURCHASED: "PURCHASED",
};

export const ENQUIRY_SOURCES = {
  REFERENCE: "REFERENCE",
};
export const DEMO_LOCATION_TYPES = {
  HOME: 1,
  OFFICE: 2
}
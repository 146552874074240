import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { LEAD_STATUS } from "../../utils/constants/lead.constants";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLES } from "../../utils/constants/main";
import { memo, useState } from "react";
import { openModal } from "../../store/actions/modalAction";
import LeadUpdateStatusController from "./LeadUpdateStatusController";
import { MODULES } from "../../utils/constants/module.constant";

export const AddAudiogramButton = memo(
  ({ status, isMedicalProduct, id, callBack = () => {} }) => {
    const [currentStatus, setCurrentStatus] = useState(status);
    const dispatch = useDispatch();
    const updateStatus = () => {
      dispatch(
        openModal(
          <LeadUpdateStatusController
            title="Add Audiogram"
            id={id}
            status={LEAD_STATUS.AUDIOGRAM_RECEIVED}
            callBack={() => {
              setCurrentStatus(LEAD_STATUS.AUDIOGRAM_RECEIVED);
              callBack({ status: LEAD_STATUS.AUDIOGRAM_RECEIVED });
            }}
          />,
          "sm",
          undefined,
          "update-lead-status"
        )
      );
    };

    if (
      ![
        LEAD_STATUS.WILLING_TO_PURCHASE,
        LEAD_STATUS.AUDIOGRAM_ASKED,
        LEAD_STATUS.INFORMATION,
      ].includes(currentStatus) ||
      !isMedicalProduct
    )
      return <></>;
    return (
      <Button
        size="small"
        variant="contained"
        disableElevation
        onClick={(e) => {
          e.preventDefault();
          updateStatus();
        }}
      >
        Add Audiogram
      </Button>
    );
  }
);
const LeadStatusUpdateButtons = ({
  id,
  currentStatus,
  isMedicalProduct,
  hasDestributor,
  defaultData,
  onStatusChange = () => {},
}) => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const updateStatus = (status, title) => {
    dispatch(
      openModal(
        <LeadUpdateStatusController
          id={id}
          title={title}
          status={status}
          defaultData={defaultData}
          callBack={() => {
            onStatusChange(status);
          }}
        />,
        "sm",
        undefined,
        "update-lead-status"
      )
    );
  };

  if (loading) return <CircularProgress size={20} />;

  if (
    (!user.data.modules?.includes(MODULES.LEAD_MODULE) && !user.data.modules?.includes(MODULES.AUDIOLOGY_APPROVAL_MODULE)) ||
    (user.data.role == USER_ROLES.AUDIOLOGIST &&
      currentStatus != LEAD_STATUS.AUDIOGRAM_RECEIVED)
  )
    return <></>;

  if (currentStatus == LEAD_STATUS.INFORMATION) {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Button
          onClick={() =>
            updateStatus(LEAD_STATUS.WILLING_TO_PURCHASE, "Update Lead")
          }
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: 200 }}
        >
          Update Lead
        </Button>
      </Box>
    );
  }

  if (currentStatus == LEAD_STATUS.WILLING_TO_PURCHASE) {
    if (!isMedicalProduct)
      return (
        <Box sx={{ display: "flex" }}>
          <Button
            onClick={() =>
              updateStatus(LEAD_STATUS.PURCHASED, "Mark as purchased")
            }
            variant="contained"
            size="small"
            sx={{ px: 2, py: 0, minWidth: 0 }}
            color="success"
          >
            Purchased
          </Button>
          <Button
            onClick={() =>
              updateStatus(LEAD_STATUS.NOT_INTERESTED, "Mark as Not Interested")
            }
            variant="contained"
            size="small"
            sx={{ px: 2, py: 0, minWidth: 0, ml: 2 }}
            color="error"
          >
            Not Interested
          </Button>
          <Button
            onClick={() => updateStatus(LEAD_STATUS.NOT_WORKING)}
            variant="contained"
            size="small"
            sx={{ px: 2, py: 0, minWidth: 0, ml: 2 }}
            color="warning"
          >
            Not Working
          </Button>
        </Box>
      );

    return (
      <Button
        onClick={() =>
          updateStatus(LEAD_STATUS.AUDIOGRAM_RECEIVED, "Add Audiogram")
        }
        variant="contained"
        size="small"
        sx={{ px: 2, py: 0, minWidth: 0 }}
      >
        Add Audiogram
      </Button>
    );
  }

  if (currentStatus == LEAD_STATUS.AUDIOGRAM_ASKED)
    return (
      <Button
        onClick={() =>
          updateStatus(LEAD_STATUS.AUDIOGRAM_RECEIVED, "Add Audiogram")
        }
        variant="contained"
        size="small"
        sx={{ px: 2, py: 0, minWidth: 0 }}
      >
        Add Audiogram
      </Button>
    );
    

  if (
    currentStatus == LEAD_STATUS.AUDIOGRAM_RECEIVED &&
    (user.data.role == USER_ROLES.ADMIN ||
      user.data.role == USER_ROLES.AUDIOLOGIST)
  ) {
    return (
      <Box sx={{ display: "flex" }}>
        <Button
          onClick={() =>
            updateStatus(LEAD_STATUS.AUDIOGRAM_APPROVED, "Approve Audiogram ")
          }
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: 0 }}
          color="warning"
        >
          Approve Audiogram
        </Button>
      </Box>
    );
  } else if (
    currentStatus == LEAD_STATUS.AUDIOGRAM_RECEIVED
    //  && user.data.role == USER_ROLES.AUDIOLOGIST
  ) {
    return <Typography>Waiting for approval</Typography>;
  }

  if (currentStatus == LEAD_STATUS.AUDIOGRAM_APPROVED) {
    if (hasDestributor) {
      return (
        <Button
          onClick={() =>
            updateStatus(LEAD_STATUS.DEMO_SCHEDULED, "Schedule Demo")
          }
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: 0 }}
        >
          Schedule Demo
        </Button>
      );
    }
    return (
      <Button
        onClick={() => updateStatus(LEAD_STATUS.DISPATCHED_FOR_DEMO)}
        variant="contained"
        size="small"
        sx={{ px: 2, py: 0, minWidth: 0 }}
      >
        Dispatch For Demo
      </Button>
    );
  }

  if (currentStatus == LEAD_STATUS.DEMO_SCHEDULED) {
    return (
      <Box sx={{ display: "flex", minWidth: "300px" }}>
        <Button
          onClick={() =>
            updateStatus(LEAD_STATUS.PURCHASED, "Mark as purchased")
          }
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: "100px" }}
          color="success"
        >
          Purchased
        </Button>
        <Button
          onClick={() => updateStatus(LEAD_STATUS.NOT_WORKING)}
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: 0, ml: 2 }}
          color="warning"
        >
          Not Working
        </Button>
        <Button
          onClick={() =>
            updateStatus(LEAD_STATUS.NOT_INTERESTED, "Mark as Not Interested")
          }
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: 0, ml: 2 }}
          color="error"
        >
          Not Interested
        </Button>
      </Box>
    );
  }

  if (currentStatus == LEAD_STATUS.DISPATCHED_FOR_DEMO) {
    return (
      <Box sx={{ display: "flex" }}>
        <Button
          onClick={() =>
            updateStatus(LEAD_STATUS.PURCHASED, "Mark as purchased")
          }
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: 0 }}
          color="success"
        >
          Purchased
        </Button>
        <Button
          onClick={() => updateStatus(LEAD_STATUS.RETURNED)}
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: 0, ml: 2 }}
          color="warning"
        >
          Return Device
        </Button>
      </Box>
    );
  }

  if (currentStatus == LEAD_STATUS.RETURNED) {
    return (
      <Box sx={{ display: "flex" }}>
        <Button
          onClick={() =>
            updateStatus(LEAD_STATUS.PURCHASED, "Mark as purchased")
          }
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: 0 }}
          color="success"
        >
          Purchased
        </Button>
        <Button
          onClick={() =>
            updateStatus(LEAD_STATUS.NOT_INTERESTED, "Mark as Not Interested")
          }
          variant="contained"
          size="small"
          sx={{ px: 2, py: 0, minWidth: 0, ml: 2 }}
          color="error"
        >
          Not Interested
        </Button>
      </Box>
    );
  }

  return <></>;
};
export default LeadStatusUpdateButtons;

import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getComplainApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.complainFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateComplainField = async (data) => {
  const callResponse = await axios({
    url: endpoints.complainUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getStatusCountApi = async params => {
    const callResponse = await axios({
      url: endpoints.complainCount,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };
  

export default getComplainApi;

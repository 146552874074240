import { useEffect, useState,memo } from "react";
import ListUi from "./ListUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { useMemo } from "react";
import { ButtonBase } from "@mui/material";
import { getAudiogramApi } from "../../apis/audiogram.api";
import AudiogramStatusComponent from "./AudiogramStatusComponent";
import LeadStatusUpdateButtons from "../lead/LeadStatusUpdateButtons";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import { openModal } from "../../store/actions/modalAction";
import LeadDetailedViewController from "../lead/LeadDetailedViewController";

const LeadNameButton = memo(({ params, setParams }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      openModal(
        <LeadDetailedViewController id={params?._id} />,
        "md",
        undefined,
        "lead-details"
      )
    );
  };

  return (
    <ButtonBase size="small" onClick={onClick}>
      {params.first_name ?? ""} {params.last_name ?? ""} s
    </ButtonBase>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Audiogram ";
  const fetchApi = getAudiogramApi;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =><LeadNameButton params={params} />
          // params.first_name + " " + (params.last_name ?? ""),
      },
      {
        id: 2,
        fieldName: "phone",
        label: "Phone",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 8,
        fieldName: "area",
        label: "Area",
        align: "left",        
        renderValue: (params, setParams) =>params?.area?.area_name??""
      },
      {
        id: 3,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (
          <AudiogramStatusComponent
            onlyview
            params={params}
            setParams={setParams}
          />
        ),
      },
      {
        id: 4,
        fieldName: "audiogram",
        label: "Audiogram File",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) =>
          params.audiogram && params.audiogram != "" ? (
            <FileDownloadComponent src={params.audiogram} />
          ) : (
            <></>
          ),
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <LeadStatusUpdateButtons
            id={params?._id}
            currentStatus={params?.status}
            isMedicalProduct={params?.is_medical_approval_needed}
            hasDestributor={params?.has_a_distributor}
            onStatusChange={(status) => {
              setParams({ ...params, status });
            }}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["first_name", "last_name", "phone"],
    sort: "",
    sortDirection: 1,
    parentStatus: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;

import { Button, ButtonGroup, Paper, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import DataTable from "../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import { CALLPOOL_STATUS } from "../../utils/constants/lead.constants";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  loading,
  columns,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>


            </FilterTitleBox>

            <FiltersBox mt={3}>
            <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex" }}
                flex={1}
              >
                <TimeRangeSelector onChange={(changedVal)=>{setFilters({...filters,...changedVal})}} />
              </PaddingBoxInDesktop>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
                pl={3}
                flex={1}
              >
                <AsynSearchBar
                  fullWidth
                  title="Search By Phone "
                  size="small"
                  placeholder={"Search By Phone "}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                />
              </PaddingBoxInDesktop>
            </FiltersBox>
            <FiltersBox mt={3}>
            <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex" }}
                flex={1}
              >
                <ButtonGroup disableElevation>
                  <Button variant={filters.status == CALLPOOL_STATUS.PENDING ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, status: CALLPOOL_STATUS.PENDING })} >Pending</Button>
                  <Button variant={filters.status == CALLPOOL_STATUS.CALLED ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, status: CALLPOOL_STATUS.CALLED })} >Called</Button>
                </ButtonGroup>
              </PaddingBoxInDesktop>
            </FiltersBox>
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListUi;

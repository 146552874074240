import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getDealershipApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.dealershipFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateDealershipField = async (data) => {
  const callResponse = await axios({
    url: endpoints.dealershipUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getDealershipStatusCountApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.dealershipCount,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export default getDealershipApi;

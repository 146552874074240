import { Paper, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import DataTable from "../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import CSRCount from "./CSRCount";
import SubmitButton from "../../components/button/SubmitButton";
import { USER_ROLES } from "../../utils/constants/main";
import { useSelector } from "react-redux";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  fetchList,
  loading,
  columns,
  exportLoading,
}) => {
  const user = useSelector((state) => state.user);
  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox mb={2}>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Box mb={2} sx={{ display: "flex" }}>
                <Box mr={2} />
              </Box>
            </FilterTitleBox>
            <CSRCount setFilters={setFilters} filters={filters} />

            <FiltersBox mt={3}>
              <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}>
                <Box>
                  {user.data.role == USER_ROLES.ADMIN && (
                    <Box>
                      <SubmitButton
                        loading={exportLoading}
                        variant="contained"
                        onClick={() => fetchList(true)}
                        title={"Export"}
                      />
                    </Box>
                  )}
                </Box>
              </PaddingBoxInDesktop>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1 }}
                pl={3}
              ></PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
                pl={3}
              >
                <AsynSearchBar
                  fullWidth
                  title="Search By Name "
                  size="small"
                  placeholder={"Search By Name "}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                />
              </PaddingBoxInDesktop>
            </FiltersBox>
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListUi;

import { Grid, Skeleton } from "@mui/material";
import { memo, useEffect, useState } from "react";
import ClickButton from "../../components/button/ClickButton";
import { getStatusCountApi } from "../../apis/complain.api";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { getCSRStatusCountApi } from "../../apis/csr.api";
import { getDealershipStatusCountApi } from "../../apis/dealership.api";

const DealershipCountComponent = ({ filters, setFilters }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchCountFun = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getDealershipStatusCountApi({ ...filters }),
        (response) => {
          setData(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  useEffect(() => {
    fetchCountFun();
  }, []);

  if (loading)
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Skeleton variant="rounded" width="100%" height="150px" />
        </Grid>
        <Grid item xs={6} md={3}>
          <Skeleton variant="rounded" width="100%" height="150px" />
        </Grid>
        <Grid item xs={6} md={3}>
          <Skeleton variant="rounded" width="100%" height="150px" />
        </Grid>

        <Grid item xs={6} md={3}>
          <Skeleton variant="rounded" width="100%" height="150px" />
        </Grid>
      </Grid>
    );

  if (data.length > 0)
    return (
      <Grid container spacing={2}>
        {data.map((item) => (
          <Grid item key={item.status} xs={6} md={12 / data.length}>
            <ClickButton
              onClick={() =>
                setFilters({
                  ...filters,
                  status: item.status,
                  pageNo: 1,
                })
              }
              active={filters.status == item.status}
              title={item.count}
              subTitle={item.label}
            />
          </Grid>
        ))}
      </Grid>
    );

  return <></>;
};

export default memo(DealershipCountComponent);

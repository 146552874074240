import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch } from "react-redux";

import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  CSRStatusColor,
  ComplainStatusColor,
  DealershipStatusColor,
  fetchAllowedStatusForComplain,
  
  findNameByRole,
  findObjectKeyByValue,
  leadStatusColor,
  titleCase,
} from "../../utils/helpers/main";
import {
  Avatar,
  Badge,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  
  COMPLAIN_STATUS,
  CSR_STATUS,
  DEALERSHIP_STATUS,
} from "../../utils/constants/main";

import { StyledSearchBar } from "../../components/inputs/SearchBar";
// import LeadStatusUpdateController from "./status/LeadStatusUpdateController";

const DealershipStatusComponent = memo(({ params, setParams, onlyview }) => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(params.status);

  const [loading, setLoading] = useState(false);

  const allowedUpdates = fetchAllowedStatusForComplain(parseInt(status));

  const handleChange = (e) => {
    // dispatch(
    //   openModal(
    //     <LeadStatusUpdateController
    //       status={e.target.value}
    //       id={params._id}
    //       callBack={() => {
    //         setStatus(e.target.value);
    //       }}
    //     />,
    //     "sm"
    //   )
    // );
  };

  useEffect(() => {
    if (params.status != status) {
      setStatus(params.status);
    }
  }, [params.status]);

  if (!status) return <>NA</>;
  if (loading) return <CircularProgress fontSize="inherit" />;
  if (allowedUpdates && !onlyview)
    return (
      <Select
        disableClearable
        value={status}
        size="small"
        fullWidth
        onChange={handleChange}
        // renderInput={(params) => (
        //   <StyledSearchBar
        //     placeholder="Select Status"
        //     {...params}
        //     size="small"
        //   />
        // )}
      >
        {allowedUpdates.map((statusVal) => (
          <MenuItem value={statusVal}>
            {findObjectKeyByValue(statusVal, DEALERSHIP_STATUS)}
          </MenuItem>
        ))}
      </Select>
    );
  else {
    return (
      <Chip
        size="small"
        key={status}
        color={DealershipStatusColor(status)}
        label={findObjectKeyByValue(status, DEALERSHIP_STATUS)}
      />
    );
  }
});
export default DealershipStatusComponent;

import { getAllEnquiryTypesApi } from "../../apis/enquiryTypes.api";
import { actions } from "../../utils/constants/main";
import { callApiAction } from "./commonAction";

export const fetchEnquiryTypeAction = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_ENQUIRY_LOADING });
    dispatch(
      callApiAction(
        async () => await getAllEnquiryTypesApi(data),
        (response) => {
          dispatch({ type: actions.SET_ENQUIRY_DATA, data: response });
          onSuccess(response);
        },
        (err) => {
          dispatch({ type: actions.CLEAR_ENQUIRY_DATA });
          onError(err);
        }
      )
    );
  };
};
export const addEnquiryTypeData = (newDoc) => {
  return { type: actions.ADD_ENQUIRY_DATA, data: newDoc };
};
export const clearEnquiryTypeData = (newDoc) => {
  return { type: actions.CLEAR_ENQUIRY_DATA };
};

export const MODULES = {
    USER_MODULE: 1,
    CALL_MODULE: 2,
    LEAD_MODULE: 3,

    CSR_MODULE: 4,
    COMPLAIN_MODULE: 5,
    DEALERSHIP_MODULE: 6,
    AUDIOLOGY_APPROVAL_MODULE: 7,
    SECRETS_MANAGEMENT_MODULE: 8,

    LEAD_SOURCE_MODULE: 9,
    AREA_MODULE: 10,
    PRODUCT_MODULE: 11,
    COMMUNICATION_CHANNEL_MODULE: 12,
    LEAD_TYPE_MODULE: 13,
    TEMPLATE_MODULE: 14,
    CALLPOOL_MODULE: 15
};
export const MODULES_DETAILS = {
   [MODULES.CALL_MODULE] :{
    routeName:"Inbound Calls"
   },
   [MODULES.LEAD_MODULE] :{
    routeName:"Outbound Calls"
   }
};


/*
  //user wise access
  
  ADMIN: *
      USER_MODULE
      CALL_MODULE
      LEAD_MODULE
      CSR_MODULE
      COMPLAIN_MODULE
      DEALERSHIP_MODULE
      AUDIOLOGY_APPROVAL_MODULE
      SECRETS_MANAGEMENT_MODULE
      LEAD_SOURCE_MODULE
      AREA_MODULE
      PRODUCT_MODULE
      COMMUNICATION_CHANNEL_MODULE
      LEAD_TYPE_MODULE
      TEMPLATE_MODULE
  
  CRM_TEAM: *
      CALL_MODULE
      LEAD_MODULE
  
  SUB_ADMIN: *
      CSR_MODULE
      COMPLAIN_MODULE
      DEALERSHIP_MODULE
  
  AUDIOLOGIST: *
      AUDIOLOGY_APPROVAL_MODULE
  
  DISTRIBUTOR: *
      LEAD_MODULE
  */

import { getAllSourcesApi } from "../../apis/sources.api";
import { actions } from "../../utils/constants/main";
import { callApiAction } from "./commonAction";

export const fetchSourceAction = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_SOURCE_LOADING });
    dispatch(
      callApiAction(
        async () => await getAllSourcesApi(data),
        (response) => {
          dispatch({ type: actions.SET_SOURCE_DATA, data: response });
          onSuccess(response);
        },
        (err) => {
          dispatch({ type: actions.CLEAR_SOURCE_DATA });
          onError(err);
        }
      )
    );
  };
};
export const addSourceData = (newDoc) => {
  return { type: actions.ADD_SOURCE_DATA, data: newDoc };
};
export const clearSourceData = (newDoc) => {
  return { type: actions.CLEAR_SOURCE_DATA };
};

import { useEffect, useState } from "react";
import ListUi from "./ListUi";
import { Chip } from "@mui/material";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { LEAD_STATUS } from "../../utils/constants/lead.constants";
import { useMemo } from "react";
import { getDispatchedApi } from "../../apis/dispatched.api";
import { findObjectKeyByValue } from "../../utils/helpers/main";

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Dispatch Status ";
  const fetchApi = getDispatchedApi;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          params.first_name + " " + (params.last_name ?? ""),
      },
      {
        id: 2,
        fieldName: "phone",
        label: "Phone",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 3,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (
          <Chip
            size="small"
            color="warning"
            label={findObjectKeyByValue(params.status, LEAD_STATUS)}
          />
        ),
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["first_name", "last_name", "phone"],
    sort: "",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;

import {
  LEAD_ANALYTICS_STATUS,
  LEAD_STATUS,
  AUDIOGRAM_PARENT_STATUS,
} from "../constants/lead.constants";

export const findCustomerDetailsFromCallsArray = (arr = []) => {
  const obj = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    country_code: "",
    phone: "",
    address: "",
    pin: "",
    city: "",
    state: "",
    country: "",
  };

  arr?.forEach((item) => {
    if (obj.first_name == "" && item.first_name && item.first_name != "") {
      obj["first_name"] = item.first_name;
      obj["middle_name"] = item.middle_name;
      obj["last_name"] = item.last_name;
    }

    if (obj.email == "" && item.email && item.email != "") {
      obj["email"] = item.email;
    }
    if (obj.phone == "" && item.phone && item.phone != "") {
      obj["country_code"] = item.country_code;
      obj["phone"] = item.phone;
    }
    if (obj.address == "" && item.address && item.address != "") {
      obj["address"] = item.address;
    }
    if (obj.pin == "" && item.pin && item.pin != "") {
      obj["pin"] = item.pin;
      obj["city"] = item.city;
      obj["state"] = item.state;
      obj["country"] = item.country;
    }
  });

  return obj;
};

export const fetchAllowedStatusAccrodingAnalyticSatatus = (currentStatus) => {
  switch (currentStatus) {
    // case null:
    //   // return Object.values(LEAD_STATUS);
    //   return [LEAD_STATUS.INFORMATION, LEAD_STATUS.WILLING_TO_PURCHASE, LEAD_STATUS.AUDIOGRAM_ASKED, LEAD_STATUS.AUDIOGRAM_APPROVED, LEAD_STATUS.DEMO_SCHEDULED, LEAD_STATUS.DISPATCHED_FOR_DEMO, LEAD_STATUS.RETURNED, LEAD_STATUS.NOT_INTERESTED, LEAD_STATUS.NOT_WORKING, LEAD_STATUS.PURCHASED, LEAD_STATUS.AUDIOGRAM_REJECTED]
    case LEAD_ANALYTICS_STATUS.PENDING:
      return [LEAD_STATUS.INFORMATION, LEAD_STATUS.WILLING_TO_PURCHASE];
    case LEAD_ANALYTICS_STATUS.WIP:
      return [
        LEAD_STATUS.AUDIOGRAM_ASKED,
        LEAD_STATUS.AUDIOGRAM_RECEIVED,
        LEAD_STATUS.AUDIOGRAM_APPROVED,
        LEAD_STATUS.DEMO_SCHEDULED,
        LEAD_STATUS.DISPATCHED_FOR_DEMO,
        LEAD_STATUS.RETURNED,
      ];
    case LEAD_ANALYTICS_STATUS.CLOSED:
      return [
        LEAD_STATUS.NOT_INTERESTED,
        LEAD_STATUS.NOT_WORKING,
        LEAD_STATUS.PURCHASED,
        LEAD_STATUS.AUDIOGRAM_REJECTED,
      ];
    default:
      return [];
  }
};

export const fetchAllowedAudiogramStatus = (tab) => {
  switch (tab) {
    case 1:
      return [
        {
          title: "Audiogram Approved",
          value: LEAD_STATUS.AUDIOGRAM_APPROVED,
        },
      ];

    case 2:
      return [
        {
          title: "Demo Scheduled",
          value: LEAD_STATUS.DEMO_SCHEDULED,
        },
        {
          title: "Dispatched For Demo",
          value: LEAD_STATUS.DISPATCHED_FOR_DEMO,
        },
        {
          title: "Returned",
          value: LEAD_STATUS.RETURNED,
        },
        {
          title: "Purchased",
          value: LEAD_STATUS.PURCHASED,
        },
        {
          title: "Not Interested",
          value: LEAD_STATUS.NOT_INTERESTED,
        },
        {
          title: "Not Working",
          value: LEAD_STATUS.NOT_WORKING,
        },
      ];

    default:
      return [];
  }
};

export const fetchAllowedStatusAccrodingAudioGramStatus = (currentStatus) => {
  switch (currentStatus) {
    // case null:
    //   // return Object.values(LEAD_STATUS);
    //   return [LEAD_STATUS.INFORMATION, LEAD_STATUS.WILLING_TO_PURCHASE, LEAD_STATUS.AUDIOGRAM_ASKED, LEAD_STATUS.AUDIOGRAM_APPROVED, LEAD_STATUS.DEMO_SCHEDULED, LEAD_STATUS.DISPATCHED_FOR_DEMO, LEAD_STATUS.RETURNED, LEAD_STATUS.NOT_INTERESTED, LEAD_STATUS.NOT_WORKING, LEAD_STATUS.PURCHASED, LEAD_STATUS.AUDIOGRAM_REJECTED]
    case AUDIOGRAM_PARENT_STATUS.PENDING_APPROVAL:
      return [LEAD_STATUS.AUDIOGRAM_APPROVED];
    case AUDIOGRAM_PARENT_STATUS.ACTIONED:
      return [
        LEAD_STATUS.NOT_INTERESTED,
        LEAD_STATUS.NOT_WORKING,
        LEAD_STATUS.PURCHASED,
        LEAD_STATUS.DEMO_SCHEDULED,
        LEAD_STATUS.DISPATCHED_FOR_DEMO,
        LEAD_STATUS.RETURNED,
      ];

    default:
      return [];
  }
};

export const LeadStatusColor = (currentStatus) => {
  if (
    fetchAllowedStatusAccrodingAnalyticSatatus(
      LEAD_ANALYTICS_STATUS.PENDING
    ).includes(currentStatus)
  ) {
    return "info";
  }

  if (
    fetchAllowedStatusAccrodingAnalyticSatatus(
      LEAD_ANALYTICS_STATUS.WIP
    ).includes(currentStatus)
  ) {
    return "warning";
  }

  if (currentStatus == LEAD_STATUS.PURCHASED) {
    return "success";
  }

  return "error";
};

export const AudiogramStatusColor = (currentStatus) => {
  if (
    fetchAllowedStatusAccrodingAudioGramStatus(
      AUDIOGRAM_PARENT_STATUS.PENDING_APPROVAL
    ).includes(currentStatus)
  ) {
    return "info";
  }

  if (
    fetchAllowedStatusAccrodingAudioGramStatus(
      AUDIOGRAM_PARENT_STATUS.ACTIONED
    ).includes(currentStatus)
  ) {
    return "warning";
  }

  if (currentStatus == LEAD_STATUS.PURCHASED) {
    return "success";
  }

  return "error";
};

import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getAllEnquiryTypesApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.enquiryTypeAll,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getEnquiryTypesApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.enquiryTypeFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getEnquiryTypeByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.enquiryTypeFetchByid,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addEnquiryTypeApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.enquiryTypeBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateEnquiryTypeField = async (data) => {
  const callResponse = await axios({
    url: endpoints.enquiryTypeUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteEnquiryTypeApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.enquiryTypeDelete,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export default getEnquiryTypesApi;

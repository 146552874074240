import { createTheme } from "@mui/material";

const colorTheme = {
  palette: {
    primary: {
      main: "#C04F2C",
      light: "#F9EEEA",
    },
    secondary: {
      main: "#F9EEEA",
    },
    dark: {
      main: "#000",
      200: "rgba(158, 158, 158, 0.12)",
    },
    light: {
      main: "#fff",
      200: "#E5E5E5",
    },
    grey: {
      main: "#e5e5e5",
    },
    text: {
      primary: "rgb(33, 43, 54)",

      secondary: "rgb(99, 115, 129)",
    },
    orange: {
      main: "rgba(255, 62, 29, 1)",
    },
    tab: {
      main: "#9fb1e8",
    },
  },
};

export default colorTheme;

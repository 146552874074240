import { actions } from "../../utils/constants/main";

const initialState = {
  loading: true,
  data: [],
};
const distributionAreaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DISTRIBUTION_AREA_DATA:
      return { ...state, loading: false, data: action.data };
    case actions.START_DISTRIBUTION_AREA_LOADING:
      return { ...state, loading: true };
    case actions.ADD_DISTRIBUTION_AREA_DATA:
      const newDoc = action.data;
      const newData = [...state.data];
      newData.push(newDoc);
      newData.sort((a, b) => a.area_name - b.area_name);
      return { ...state, data: newData };
    case actions.CLEAR_DISTRIBUTION_AREA_DATA:
      return { ...state, loading: false, data: [] };
    default:
      return { ...state };
  }
};
export default distributionAreaReducer;

import axios from "axios";
import { getHeaders } from "../utils/helpers/main";
import endpoints from "./endpoints";

export const getReferralApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.referralFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getReferralByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.referralFetchById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addReferralApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.referralBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateReferralField = async (data) => {
  const callResponse = await axios({
    url: endpoints.referralStatusUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getReferralStatusCountApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.referralCount,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export default getReferralApi;

import { Refresh } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { memo } from "react";

const DynamicDataDropDown = ({
  reducerDataObj = { loading: false, data: [] },
  titleKey = "name",
  valueKey = "_id",
  onRefresh,
  disabled = false,
  creatable = false,
  onCreate = () => {},
  inputProps = {},
  ...remainedProps
}) => {
  return (
    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
      <Box sx={{ display: "flex", flex: 1 }}>
        <Autocomplete
          fullWidth
          disableClearable
          handleHomeEndKeys
          noOptionsText={
            creatable ? (
              <MenuItem onClick={onCreate}>Add New</MenuItem>
            ) : (
              "No Options"
            )
          }
          disabled={disabled || reducerDataObj.loading}
          loading={reducerDataObj.loading}
          options={reducerDataObj.data}
          renderOption={(props, option) => (
            <MenuItem {...props}>{option[titleKey]}</MenuItem>
          )}
          getOptionLabel={(option) => option[titleKey]}
          renderInput={(params) => (
            <TextField fullWidth {...params} {...inputProps} />
          )}
          {...remainedProps}
        />
      </Box>
      {onRefresh && (
        <Tooltip title="Refresh">
          <IconButton
            onClick={onRefresh}
            disabled={disabled || reducerDataObj.loading}
          >
            <Refresh />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default memo(DynamicDataDropDown);

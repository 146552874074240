import { memo, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import ModulePermissionUi from "./ModulePermissionUi";
import { updateModulesPermission } from "../../apis/user.api";

const ModulePermissionController = ({ callBack = () => {}, id, modules }) => {
  const validate = useValidate();
  const dispatch = useDispatch();

  const modalKey = "modules";
  const title = "Modules ";

  const updateApi = updateModulesPermission;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    modules,
  });

  const validationSchemaForUpdate = useMemo(() => [], [fields]);

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);

    if (validationResponse === true) {
      setLoading(true);

      dispatch(
        callApiAction(
          async () => await updateApi({ ...fields }),
          async (response) => {
            await callBack({modules:fields.modules});
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    updateFunction();
  };

  return (
    <ModulePermissionUi
      title={title}
      modalKey={modalKey}
      loading={loading}
      isUpdate={id}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(ModulePermissionController);

import { memo, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { openModal } from "../../store/actions/modalAction";
import { Box, IconButton } from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
import { Edit } from "@mui/icons-material";
import getSourceApi from "../../apis/sources.api";

const ActionComponent = memo(({ params, setParams }) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <IconButton size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Sources ";
  const fetchApi = getSourceApi;
  const deleteApi = null;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Source Name",
        align: "left",
        sort: true,
      },
      { id: 2, fieldName: "identifier", label: "Identifier", align: "left" },
      { id: 3, fieldName: "priority", label: "Priority", align: "left" },

      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;

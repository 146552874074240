import { CircularProgress, Typography, Switch, MenuItem } from "@mui/material";
import { memo } from "react";
import FileInput from "../../components/inputs/FileInput";
import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import getProductApi from "../../apis/product.api";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {
              <>
                <CustomInput
                  autoFocus={true}
                  disabled={loading}
                  value={fields.product_name}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      product_name: e.target.value,
                    })
                  }
                  type="text"
                  label={"Product Name*"}
                />
                <CustomInput
                  disabled={loading}
                  value={fields.mrp}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      mrp: e.target.value,
                    })
                  }
                  type="text"
                  label={"MRP*"}
                />
                <CustomInput
                  disabled={loading}
                  value={fields.priority}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      priority: e.target.value,
                    })
                  }
                  type="number"
                  label={"Priority*"}
                />
              </>
            }

            <AsyncDropDown
              lazyFun={async (params) =>
                await getProductApi({
                  ...params,
                  noParent: true
                })
              }
              defaultVal={fields.parent}
              label="Select Parent Product"
              OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.product_name}</MenuItem>;
              }}
              onChange={async (changedVal) => {
                setFields({
                  ...fields,
                  parent: changedVal ? changedVal._id : null,
                });
              }}
              titleKey={"product_name"}
              valueKey={"_id"}
            />
            <Typography>Is Medical Product?</Typography>
            <Switch
              checked={fields.is_medical_product}
              onChange={(e) =>
                setFields({ ...fields, is_medical_product: e.target.checked })
              }
            ></Switch>

            <FileInput
              multi
              sx={{ width: "100%" }}
              onChange={(file, fileArr) => {
                setFields({ ...fields, file_url: fileArr });
              }}
              defaults={fields.file_url ?? []}
              accept=".pdf"
              title="Upload Brochure"
              subTitle="Only pdf files are allowed! less than 1 MB"
            />
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);

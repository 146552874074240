import { memo, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { openModal } from "../../store/actions/modalAction";
import { Box, IconButton } from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
import { Edit } from "@mui/icons-material";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import { getProductApi } from "../../apis/product.api";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <IconButton size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Products ";
  const fetchApi = getProductApi;
  const deleteApi = null;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "product_name",
        label: "Product Name",
        align: "left",
        sort: true,
      },
      {
        id: 1,
        fieldName: "parent.product_name",
        label: "Parent Product",
        align: "left",
        sort: true,
        renderValue:(params)=>params?.parent?.product_name??""
      },
      {
        id: 2,
        fieldName: "mrp",
        label: "MRP",
        align: "left",
        sort: true,
      },
      {
        id: 3,
        fieldName: "priority",
        label: "Priority",
        align: "left",
        sort: true,
      },
      {
        id: 4,
        fieldName: "is_medical_product",
        label: "Medical Product",
        align: "left",
        sort: true,
        renderValue: (params) => (params.is_medical_product ? "Yes" : "No"),
      },
      {
        id: 5,
        fieldName: "file_url",
        label: "Brochure",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params, setParams) =>
          params.file_url.map((param) => (
            <FileDownloadComponent src={param} key={param._id} />
          )),
      },

      {
        id: 6,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["product_name"],
    sort: "",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;

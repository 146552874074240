import { memo, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { Box, ButtonBase, IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { Check, Visibility } from "@mui/icons-material";
import getReferralApi, { updateReferralField } from "../../apis/referral.api";
import ReferralStatusComponent from "./ReferralStatusComponent";
import { REFERRAL_STATUS } from "../../utils/constants/main";
import MessageDilog from "../../components/MessageDilog";
import LeadDetailedViewController from "../lead/LeadDetailedViewController";
import FileDownloadComponent from "../../components/FileDownloadComponent";

const ActionComponent = memo(({ params, setParams, deleteApi, callback }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const doneFun = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateReferralField({ id: params._id }),
        (response) => {
          setParams({ ...params });
          callback(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDone = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={doneFun}
          title="Alert!"
          message={`Are you sure to your status is Discount Given "${params.referral_name}" ?`}
        />,
        "sm"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {params.status == REFERRAL_STATUS.PENDING && (
        <Tooltip title="Mark As Completed">
          <IconButton disabled={loading} size="inherit" onClick={onDone}>
            <Check color="success" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}

      {params.status == REFERRAL_STATUS.DISCOUNT_GIVEN && "NA"}
    </Box>
  );
});

const LeadNameButton = memo(({ params, setParams }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      openModal(
        <LeadDetailedViewController id={params?.lead_id?._id} />,
        "md",
        undefined,
        "lead-details"
      )
    );
  };

  return <Visibility onClick={onClick} color="primary" />;
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Referral ";
  const fetchApi = getReferralApi;
  const deleteApi = null;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "referral_name",
        label: "Referral Name",
        align: "left",
        sort: true,
      },
      {
        id: 2,
        fieldName: "referral_phone",
        label: "Referral Phone",
        align: "left",
        sort: true,
      },
      {
        id: 3,
        fieldName: "lead_id",
        label: "Lead",
        align: "left",
        renderValue: (params, setParams) => (
          <IconButton>
            <LeadNameButton params={params} />
          </IconButton>
        ),
      },
      {
        id: 4,
        fieldName: "status",
        label: "Status",
        align: "left",
        renderValue: (params, setParams) => (
          <ReferralStatusComponent
            onlyview
            params={params}
            setParams={setParams}
          />
        ),
      },
      {
        id: 5,
        fieldName: "payment_proof",
        label: "Payment Proof",
        align: "left",
        renderValue: (params) =>
          params.payment_proof && params.payment_proof != "" ? (
            <FileDownloadComponent src={params.payment_proof} />
          ) : (
            <></>
          ),
      },
      {
        id: 6,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            callback={fetchList}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["referral_name"],
    sort: "",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;

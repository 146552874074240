const mode = "production";
let domain = "";

switch (mode) {
  case "local":
    domain = "http://localhost:8000/";
    break;

  case "production":
    domain = "https://wehear-lms-backend-production.up.railway.app/";
    break;
  default:
    domain = "/";
}

const endpoints = {
  root: domain,
  fileImage: `${domain}api/file/image`,
  fileFile: `${domain}api/file/file`,
  fileBase: `${domain}api/file`,
  notificationFetch: `${domain}api/notification`,
  signIn: `${domain}api/auth/login`,
  resetToken: `${domain}api/auth/reset-token`,
  resetPassword: `${domain}api/auth/reset-password`,
  userCount: `${domain}api/user/count`,
  userAndSystemFetchByToken: `${domain}api/user/fetch-by-token`,
  userBase: `${domain}api/user/`,
  userDeleteUndo: `${domain}api/user/undo-delete`,
  userPassword: `${domain}api/user/reset-password`,
  secretsBase: `${domain}api/secrets/`,
  secretsById: `${domain}api/secrets/by-id`,

  sourcesBase: `${domain}api/sources/`,
  sourcesAll: `${domain}api/sources/all`,
  sourcesById: `${domain}api/sources/by-id`,

  areaBase: `${domain}api/area/`,
  areaAll: `${domain}api/area/all`,
  areaByid: `${domain}api/area/by-id`,

  attendenceBase: `${domain}api/attendence/`,
  attendenceStatus: `${domain}api/attendence/status`,

  teamBase: `${domain}api/team/`,
  teamById: `${domain}api/team/by-id`,

  callpoolBase: `${domain}api/callpool/`,
  callpoolDelete: `${domain}api/callpool/delete`,

  leadBase: `${domain}api/lead/`,

  chatGroupsBaseUrl: `${domain}api/chat/groups`,
  chatGrouypsById: `${domain}api/chat/groups/id`,
  chatFetch: `${domain}api/chat/chats`,

  leadStatusUpdate: `${domain}api/lead/status`,
  leadById: `${domain}api/lead/by-id`,
  leadAnalysisByStatus: `${domain}api/lead/analysis-by-status`,
  leadAnalysisBySource: `${domain}api/lead/analysis-by-source`,
  leadAnalysisBySourceAndSalesPerson: `${domain}api/lead/analysis-by-source-sales-person`,
  leadAnalysisByStatusesAndSalesPerson: `${domain}api/lead/analysis-by-status-sales-person`,

  openSolarUserBase: `${domain}api/user/open-solar`,
  userById: `${domain}api/user/fetch-by-id`,

  getautoComplete: `${domain}api/location/autocomplete`,
  getlatandLon: `${domain}api/location/lat-lon`,

  productBase: `${domain}api/product/`,
  productAll: `${domain}api/product/all`,
  productFetch: `${domain}api/product/fetch`,
  productFetchByid: `${domain}api/product/fetch-by-id`,
  productUpdate: `${domain}api/product/update`,

  channelBase: `${domain}api/channel/`,
  channelAll: `${domain}api/channel/all`,
  channelFetch: `${domain}api/channel/fetch`,
  channelFetchByid: `${domain}api/channel/fetch-by-id`,
  channelUpdate: `${domain}api/channel/update`,

  customerSearchByPhone: `${domain}api/customer/search-phone`,
  customerSuggestionPhone: `${domain}api/customer/suggest-phone`,
  enquiryReport: `${domain}api/report/enquiry-report`,

  enquiryBase: `${domain}api/enquiry/`,

  enquiryTypeBase: `${domain}api/enquiry-type/`,
  enquiryTypeAll: `${domain}api/enquiry-type/all`,
  enquiryTypeFetch: `${domain}api/enquiry-type/fetch`,
  enquiryTypeFetchByid: `${domain}api/enquiry-type/by-id`,
  enquiryTypeUpdate: `${domain}api/enquiry-type/update`,
  enquiryTypeDelete: `${domain}api/enquiry-type/delete`,

  complainFetch: `${domain}api/complain/fetch`,
  complainUpdate: `${domain}api/complain/update`,
  complainCount: `${domain}api/complain/count`,

  templateBase: `${domain}api/template/`,
  templateFetch: `${domain}api/template/fetch`,
  templateUpdate: `${domain}api/template/update`,
  templateFetchByid: `${domain}api/template/by-id`,

  csrFetch: `${domain}api/csr/fetch`,
  csrUpdate: `${domain}api/csr/update`,
  csrCount: `${domain}api/csr/count`,

  dealershipFetch: `${domain}api/dealership/fetch`,
  dealershipUpdate: `${domain}api/dealership/update`,
  dealershipCount: `${domain}api/dealership/count`,

  leadFetch: `${domain}api/lead/`,
  leadFetchById: `${domain}api/lead/by-id`,
  leadCount: `${domain}api/lead/count`,

  distributorBase: `${domain}api/distributor/all`,
  audiogramFetch: `${domain}api/lead/audiogram`,
  audiogramCount: `${domain}api/lead/audiogram/count`,

  referralBase: `${domain}api/referral/`,
  referralFetch: `${domain}api/referral/fetch`, 
  referralFetchById: `${domain}api/referral/by-id`,
  referralCount: `${domain}api/referral/count`,
  referralStatusUpdate: `${domain}api/referral/status`,
  dispatchedFetch: `${domain}api/lead/dispatch-demo`,

  reasonBase: `${domain}api/reason/`,
  reasonFetch: `${domain}api/reason/fetch`,
  reasonFetchById: `${domain}api/reason/by-id`,
};

export default endpoints;

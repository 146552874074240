import { useSelector } from "react-redux"
import DynamicDataDropDown from "../../components/inputs/DynamicDataDropDown"

export const ParentProductsList = ({ ...props }) => {
    const { product } = useSelector(state => state)
    return <DynamicDataDropDown




        reducerDataObj={{
            loading: product.loading,
            data: product?.data?.filter(item => (!item.parent || item.parent == ''))
        }}
        titleKey="product_name"
        valueKey="_id"
        inputProps={{
            label: "Select Product*",
        }}
        {...props}
    />
}

export const ChildProductsList = ({ parent, ...props }) => {
    const { product } = useSelector(state => state)
    const subProductList = product?.data?.filter(item => (item.parent == parent)) ?? []
    if (!parent)
        return <></>
    return <DynamicDataDropDown




        reducerDataObj={{
            loading: product.loading,
            data: subProductList.length > 0 ? subProductList : product?.data?.filter(item => (item._id == parent))
        }}
        titleKey="product_name"
        valueKey="_id"
        inputProps={{
            label: "Select Sub Product*",
        }}
        {...props}
    />
}
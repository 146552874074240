import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import MessageDilog from "../../components/MessageDilog";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { useMemo } from "react";
import { USER_ROLES } from "../../utils/constants/main";
import CreateController from "./CreateController";
import { Delete, Edit } from "@mui/icons-material";
import getLeadTypeApi, {
  deleteEnquiryTypeApi,
  getEnquiryTypesApi,
} from "../../apis/enquiryTypes.api";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  const deleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };
  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure to delete "${params.name}" ?`}
        />,
        "sm"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <IconButton size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>
      {user.data.role == USER_ROLES.ADMIN && !params.system_default && (
        <>
          {loading && <CircularProgress color="primary" fontSize="inherit" />}
          {!loading && (
            <IconButton disabled={loading} size="inherit" onClick={onDelete}>
              <Delete color="error" fontSize="inherit" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Enquiry Type ";
  const fetchApi = getEnquiryTypesApi;
  const deleteApi = deleteEnquiryTypeApi;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Enquiry Name",
        align: "left",
        sort: true,
      },
      { id: 2, fieldName: "identifier", label: "Identifier", align: "left" },
      { id: 3, fieldName: "priority", label: "Priority", align: "left" , sort: true},
      {
        id: 4,
        fieldName: "template_for_customer",
        label: "Template",
        align: "left",
        renderValue: (params) =>
          params.template_for_customer.length === 0
            ? "NA"
            : params.template_for_customer.map((item) => item.title).join(", "),
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "priority",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;

import { tableCellClasses } from "@mui/material";
import { createTheme } from "@mui/material";
import breakPoint from "./breakpoint";
import colorTheme from "./colorTheme";
import typography from "./typography";

const theme = createTheme({
  ...breakPoint,
  ...colorTheme,
  ...typography,
  shape: {
    borderRadius: 8,
  },
  spacing: [0, 4, 8, 16, 32, 64],
});
export default theme;

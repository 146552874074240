import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import AreaUi from "./AreaUi";
import { openModal } from "../../store/actions/modalAction";
import AreaCreateController from "../area/AreaCreateController";
import { callApiAction } from "../../store/actions/commonAction";
import { Edit } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { getAreaApi } from "../../apis/area.api";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(
      openModal(
        <AreaCreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <IconButton size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>
    </Box>
  );
});

const AreaController = () => {
  const dispatch = useDispatch();
  const title = "Area ";
  const fetchApi = getAreaApi;
  const deleteApi = null;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "area_name",
        label: "Area Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (
          <Typography textTransform="capitalize">{params.area_name}</Typography>
        ),
      },
      {
        id: 2,
        fieldName: "has_a_distributor",
        label: "Have a Distributor",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (
          <Typography textTransform="capitalize">
            {params.has_a_distributor ? "Yes" : "No"}
          </Typography>
        ),
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            callBack={fetchList}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["area_name"],
    sort: "",
    sortDirection: 1,
  });

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <AreaCreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <AreaUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default AreaController;

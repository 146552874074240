import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { useMemo } from "react";

import moment from "moment";
import { findObjectKeyByValue } from "../../utils/helpers/main";
import { CALLPOOL_STATUS } from "../../utils/constants/lead.constants";
import { deleteCallPoolApi, getCallPoolListApi } from "../../apis/callpool.api";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { closeModal, openModal } from "../../store/actions/modalAction";
import MessageDilog from "../../components/MessageDilog";
import { USER_ROLES } from "../../utils/constants/main";

const DeleteCallPoolButton = memo(({ params, setParams, modalKey }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const deleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteCallPoolApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };
  const onClick = () => {
    dispatch(
      openModal(
        <MessageDilog
          title={"Are you sure to delete " + params.phone}
          onSubmit={deleteFun}
        />,
        "sm"
      )
    );
  };

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <IconButton onClick={onClick}>
        <Delete />
      </IconButton>
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Callpool ";
  const fetchApi = getCallPoolListApi;
  const user = useSelector((state) => state);
  console.log("USER", user.user.data.role);
  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "call_time",
        label: "Call At",
        align: "left",
        sort: true,
        renderValue: (params) =>
          moment(params.call_time).format("DD/MM/YYYY hh:mm"),
      },
      {
        id: 2,
        fieldName: "phone",
        label: "Phone",
        align: "left",
        sort: true,
      },
      {
        id: 3,
        fieldName: "status",
        label: "Status",
        align: "left",
        renderValue: (params) =>
          findObjectKeyByValue(params.status, CALLPOOL_STATUS),
      },
      {
        id: 4,
        fieldName: "action",
        label: "Action",
        align: "left",
        hide:
          user.user.data.role == USER_ROLES.CRM_TEAM ||
          user.user.data.role == USER_ROLES.SUB_ADMIN ||
          user.user.data.role == USER_ROLES.AUDIOLOGIST ||
          user.user.data.role == USER_ROLES.DISTRIBUTOR,
        renderValue: (params, setParams) => (
          <DeleteCallPoolButton params={params} setParams={setParams} />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["phone"],
    sort: "call_time",
    status: CALLPOOL_STATUS.PENDING,
    sortDirection: -1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
